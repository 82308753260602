import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Tracking from "./Tracking";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  shipping_data_read,
  // upload_data_write,
  detail_error,
} from "../../store/actions";
import Lottie from "react-lottie";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.shipping_data_read();
  }

  render() {
    const { user_details, shipping_data } = this.props;
    return (
      <React.Fragment>
        <Tracking user_details={user_details} shipping_data={shipping_data} />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user_details, shipping_data, error, loading } = state.Database;

  return {
    user_details,
    shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    shipping_data_read,
    detail_error,
  })(withNamespaces()(Index))
);
