import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
  ////////
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,

  /////
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import logo from "../../assets/images/logo-dark.png";
import SweetAlert from "react-bootstrap-sweetalert";

import { MDBDataTable } from "mdbreact";
import Lottie from "react-lottie";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  BlobProvider,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { Link } from "react-router-dom";

import classnames from "classnames";

import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/database";

// import "./CSS/surveyQR.css";
import "./CSS/surveyQR.css";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//
import moment from "moment";
import moment_time_zone from "moment-timezone";
//moment_time_zone().tz("Etc/GMT")
import vat_arabic from "../../assets/images/VAT_Arabic.png"; //"../../../assets/images/VAT_Arabic.png";

/**********/

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  shipping_data_read,
  container_data_read,
  currency_data_read,
  rates_data_read,
  limited_users_data_read,
  limited_users_logs_data_read,
  detail_error,
} from "../../store/actions";
import { withNamespaces } from "react-i18next";

/**********/
const RAJHI_BANK_ACCOUNT = "158608010260172 IBAN  SA05 8000 0158 6080 10260172";
const NCB_BANK_ACCOUNT = "75400001323509 IBAN SA56 1000 0075 4000 0132 3509";
const INMA_BANK_ACCOUNT = "####";

class TasksCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesperson_name: "",
      confirm_both: false,
      startDate: new Date(),
      endDate: new Date(),
      activeTab: "1",
      activeTab_MAIN_NAV: "1",
      inputFields: [{ name: "", file: "" }],
      rates_data_extracted: {},

      selected_shipment: {},
      show_old_data: false,
      modal_2nd_tab: false,
      consignee_delivery: "",
      consignee_country: "",
      consignee_town: "",
      consignee_phone: "",
      consignee_name: "",
      consignee_modal: false,
      consignee_body: <div></div>,
      consignee_uid: "",
      country: "",
      town: "",
      phone: "",
      name: "",
      email: "",
      shipper_modal: false,
      shipper_body: <div></div>,
      uid: "",
      modal_2nd_tab: false,

      //
      old_shipper_data: [],
      old_consignee_data: [],
      old_container_data: [],
      currency_conversion_modal: false,
      currency_conversion_body: <div></div>,
      conversion_selected: {},
      //

      cargo_details: [{ quantity: "", description: "", weight: "", rate: "" }],
      payments_details: [
        { amount: "", date: "", payment_receipt_no: "", mode: "" },
      ],

      rows: [],

      rows_Payments: [],

      remarks_charges: "",
      miscellaneous_charges: "",
      transport_charges: "",
      packing_charges: "",
      hbl_charges: "",

      existing_date: [],
      hbl_number: "",
      lot_number: "",
      date_created: "",
      array_for_all_HBL_numbers: [],
      user_details: {},
      list_of_limited_access_users: {},
      raw_data_full_detail: {},
      invoice_number: "",
      invoice_details: {},
      ready: false,
      all_invoices: {},
      //
      offload_pcs: "",
      offload_lot_no: "",
      offload_date: "",
      offload_remarks: "",
      //
      delivery_pcs: "",
      delivery_lot_no: "",
      delivery_date: "",
      delivery_remarks: "",
      delivery_date_received_by: "",
      delivery_name_id_no: "",
      saving_loading_status: false,
    };
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);
    this.handleAddFields.bind(this);
    this.handleRemoveFields.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
  }

  fetch() {
    const {
      user_details,
      shipping_data,
      container_data,
      currency_data,
      rates_data,
      limited_users_data,
    } = this.props;

    var data_array = _.map(container_data, (val, uid) => {
      return { ...val, uid };
    });

    this.setState({
      user_details,
      list_of_limited_access_users: limited_users_data,
      conversion_selected: currency_data,
      rates_data_extracted: rates_data,
      old_container_data: data_array,
    });

    var existing_date = _.map(shipping_data, (val, uid) => {
      return { ...val, uid };
    });

    this.setState({
      existing_date,
    });

    //HBL DATE hbl_number_and_date Start

    var data = existing_date;
    var consignee_details_array = [];
    var shipper_details_array = [];
    data.forEach((element) => {
      if (element.full_detail !== undefined) {
        var full_detail = element.full_detail;
        if (full_detail.consignee_details !== undefined) {
          consignee_details_array.push({
            name: full_detail.consignee_details.consignee_name,
            phone: full_detail.consignee_details.consignee_phone,
            town: full_detail.consignee_details.consignee_town,
            country: full_detail.consignee_details.consignee_country,
            delivery: full_detail.consignee_details.consignee_delivery,
          });
        }
        if (full_detail.shipper_details !== undefined) {
          shipper_details_array.push({
            name: full_detail.shipper_details.name,
            phone: full_detail.shipper_details.phone,
            town: full_detail.shipper_details.town,
            country: full_detail.shipper_details.country,
            email: full_detail.shipper_details.email,
          });
        }
      }
    });

    var unique_consignee_details = _.uniqBy(
      consignee_details_array,
      function (e) {
        return e.phone;
      }
    );
    unique_consignee_details = _.uniqBy(unique_consignee_details, function (e) {
      return e.name;
    });
    var unique_shipper_details = _.uniqBy(shipper_details_array, function (e) {
      return e.phone;
    });
    unique_shipper_details = _.uniqBy(unique_shipper_details, function (e) {
      return e.name;
    });

    this.setState({
      old_shipper_data: unique_shipper_details,
      old_consignee_data: unique_consignee_details,
    });

    setTimeout(() => {
      {
        var show_to_edit = [];

        // if (this.state.existing_date.length !== 0) {
        //   var array_reversed = this.state.existing_date.reverse();
        if (existing_date.length !== 0) {
          var array_reversed = existing_date.reverse();
          array_reversed.forEach((element) => {
            show_to_edit.push({
              C_Phone: element.C_Phone,
              Consignee: element.Consignee,
              Shipper: element.Shipper,
              Lot_No: element.Lot_No,
              No: element.No,

              edit: (
                <div>
                  <Row>
                    <Col lg="12" className="text-center">
                      <Link
                        onClick={() => {
                          var full_detail = element.full_detail;

                          if (full_detail !== undefined) {
                            if (full_detail.sales_commission !== undefined) {
                              if (
                                full_detail.sales_commission
                                  .salesperson_name !== ""
                              ) {
                                this.setState({
                                  salesperson_name:
                                    full_detail.sales_commission
                                      .salesperson_name,
                                });
                              }
                            }
                            var consignee_details =
                                full_detail.consignee_details,
                              cargo_details = full_detail.cargo_details,
                              other_charges = full_detail.other_charges,
                              payments_details = full_detail.payments_details,
                              shipper_details = full_detail.shipper_details,
                              conversion = full_detail.conversion,
                              offload_delivery = full_detail.offload_delivery;
                            if (conversion) {
                              if (Object.keys(conversion).length > 0) {
                                this.setState({
                                  conversion_selected: conversion,
                                });
                              }
                            }

                            var calculations = full_detail.calculations;

                            var penalty_fee = "",
                              penalty_days = "",
                              storage_fee_ksh = "",
                              storage_fee_sr = "";

                            if (calculations.penalty_fee !== undefined) {
                              penalty_fee = calculations.penalty_fee;
                            }
                            if (calculations.penalty_days !== undefined) {
                              penalty_days = calculations.penalty_days;
                            }
                            if (calculations.storage_fee_ksh !== undefined) {
                              storage_fee_ksh = calculations.storage_fee_ksh;
                            }
                            if (calculations.storage_fee_sr !== undefined) {
                              storage_fee_sr = calculations.storage_fee_sr;
                            }

                            this.setState({
                              raw_data_full_detail: full_detail,
                              cargo_details: cargo_details,

                              penalty_fee,
                              penalty_days,
                              storage_fee_ksh,
                              storage_fee_sr,

                              consignee_country:
                                consignee_details.consignee_country,
                              consignee_delivery:
                                consignee_details.consignee_delivery,
                              consignee_name: consignee_details.consignee_name,
                              consignee_phone:
                                consignee_details.consignee_phone,
                              consignee_town: consignee_details.consignee_town,
                              hbl_charges: other_charges.hbl,
                              packing_charges: other_charges.packing_charges,
                              miscellaneous_charges:
                                other_charges.miscellaneous_charges,
                              remarks_charges: other_charges.remarks_charges,
                              transport_charges:
                                other_charges.transport_charges,
                              payments_details: payments_details,
                              country: shipper_details.country,
                              name: shipper_details.name,
                              phone: shipper_details.phone,
                              town: shipper_details.town,
                              email: element.Email,
                            });

                            if (offload_delivery !== undefined) {
                              this.setState({
                                offload_pcs: offload_delivery.offload_pcs,
                                offload_lot_no: offload_delivery.offload_lot_no,
                                offload_date: offload_delivery.offload_date,
                                offload_remarks:
                                  offload_delivery.offload_remarks,
                                //
                                delivery_pcs: offload_delivery.delivery_pcs,
                                delivery_lot_no:
                                  offload_delivery.delivery_lot_no,
                                delivery_date: offload_delivery.delivery_date,
                                delivery_remarks:
                                  offload_delivery.delivery_remarks,
                                delivery_date_received_by:
                                  offload_delivery.delivery_date_received_by,
                                delivery_name_id_no:
                                  offload_delivery.delivery_name_id_no,
                              });
                            } else {
                              this.setState({
                                offload_pcs: "",
                                offload_lot_no: "",
                                offload_date: "",
                                offload_remarks: "",
                                //
                                delivery_pcs: "",
                                delivery_lot_no: "",
                                delivery_date: "",
                                delivery_remarks: "",
                                delivery_date_received_by: "",
                                delivery_name_id_no: "",
                              });
                            }
                          } else {
                            this.setState({
                              cargo_details: [
                                {
                                  quantity: "",
                                  description: "",
                                  weight: "",
                                  rate: "",
                                },
                              ],
                              penalty_fee: "",
                              penalty_days: "",
                              storage_fee_ksh: "",
                              storage_fee_sr: "",

                              consignee_country: "",
                              consignee_delivery: element.Delivery,
                              consignee_name: element.Consignee,
                              consignee_phone: element.C_Phone,
                              consignee_town: "",
                              hbl_charges: "",
                              packing_charges: "",
                              miscellaneous_charges: "",
                              remarks_charges: "",
                              transport_charges: "",
                              payments_details: [{ amount: "", date: "" }],
                              // address: "",
                              country: "",
                              email: "",
                              name: element.Shipper,
                              phone: "",
                              town: element.S_Town,

                              offload_pcs: "",
                              offload_lot_no: "",
                              offload_date: "",
                              offload_remarks: "",
                              //
                              delivery_pcs: "",
                              delivery_lot_no: "",
                              delivery_date: "",
                              delivery_remarks: "",
                              delivery_date_received_by: "",
                              delivery_name_id_no: "",
                            });
                          }
                          var lot_number = element.Lot_No,
                            hbl_number = element.No,
                            date_created = element.Date;

                          var invoice_number = "";

                          this.setState({
                            invoice_number,
                            selected_shipment: element,
                            show_old_data: true,
                            modal_2nd_tab: false,
                            lot_number,
                            hbl_number,
                            date_created,
                          });

                          // this.hbl_number_and_date();
                        }}
                        className="mr-3 text-primary text-center"
                      >
                        <i
                          className="mdi mdi-pencil font-size-18 "
                          id="edittooltip"
                        ></i>
                        <UncontrolledTooltip
                          placement="top"
                          target="edittooltip"
                        >
                          Edit Delivery Status
                        </UncontrolledTooltip>
                      </Link>
                    </Col>
                  </Row>
                </div>
              ),
            });
          });
        }

        const data = {
          columns: [
            {
              label: "HBL",
              field: "No",
              sort: "asc",
              width: 150,
            },
            {
              label: "Lot",
              field: "Lot_No",
              sort: "asc",
              width: 270,
            },
            {
              label: "Shipper",
              field: "Shipper",
              sort: "asc",
              width: 200,
            },
            {
              label: "Consignee",
              field: "Consignee",
              sort: "asc",
              width: 200,
            },
            {
              label: "Consignee Phone",
              field: "C_Phone",
              sort: "asc",
              width: 200,
            },

            {
              label: "Edit",
              field: "edit",
              sort: "asc",
              width: 200,
            },
          ],
          rows: show_to_edit,
        };

        var body = (
          <div>
            <MDBDataTable responsive striped bordered data={data} />
          </div>
        );

        this.setState({
          modal_2nd_tab: true,
          body,
        });
      }
    }, 1000);
  }

  handleAddRow = () => {
    const item = {
      name: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleAddRow_Payments = () => {
    const item = {
      name: "",
    };
    this.setState({
      rows_Payments: [...this.state.rows_Payments, item],
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleTab_MAIN_NAV(tab) {
    if (this.state.activeTab_MAIN_NAV !== tab) {
      this.setState({
        activeTab_MAIN_NAV: tab,
      });
    }
  }

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  // Function for Create Input Fields
  handleAddFields = () => {
    var values = this.state.inputFields;
    values.push({ name: "", file: "" });
    this.setState({ inputFields: values });
  };

  // Function for Remove Input Fields
  handleRemoveFields = (index) => {
    var values = this.state.inputFields;
    values.splice(index, 1);
    this.setState({ inputFields: values });
  };

  tab1() {
    const {
      old_shipper_data,

      country,
      town,
      phone,
      // address,
      name,
      email,
      currency_converter_data,
    } = this.state;
    var export_data = [];
    var show_to_edit = [];

    var conversion_data = [];

    if (old_shipper_data.length !== 0) {
      export_data.push([
        "Name",
        // "Address",
        "Phone",
        "Town",
        "Country",
        "Email",
      ]);
      old_shipper_data.forEach((element) => {
        export_data.push([
          element.name,
          // element.address,
          element.phone,
          element.town,
          element.country,
          element.email,
        ]);

        show_to_edit.push({
          name: element.name,
          // address: element.address,
          phone: element.phone,
          town: element.town,
          country: element.country,
          email: element.email,
          edit: (
            <div>
              <Row>
                <Col lg="12" className="text-center">
                  <Link
                    onClick={() => {
                      this.setState({
                        // address: element.address,
                        country: element.country,
                        email: element.email,
                        name: element.name,
                        phone: element.phone,
                        town: element.town,
                        uid: element.uid,
                        show_old_data: true,
                        shipper_modal: false,
                      });
                    }}
                    className="mr-3 text-primary text-center"
                  >
                    <i
                      className="mdi mdi-pencil font-size-18 "
                      id="edittooltip"
                    ></i>
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </Col>
              </Row>
            </div>
          ),
        });
      });
    }
    const { conversion_selected } = this.state;
    return (
      <Row>
        <Modal
          isOpen={this.state.shipper_modal}
          size="lg"
          toggle={() => {
            this.setState((prevState) => ({
              modal_standard: !prevState.shipper_modal,
            }));
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              List Of All Shippers
            </h5>
            <button
              type="button"
              onClick={() => this.setState({ shipper_modal: false })}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>{this.state.shipper_body}</div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.currency_conversion_modal}
          size="lg"
          toggle={() => {
            this.setState((prevState) => ({
              currency_conversion_modal: !prevState.currency_conversion_modal,
            }));
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              List Of All Currency Pairs
            </h5>
            <button
              type="button"
              onClick={() =>
                this.setState({ currency_conversion_modal: false })
              }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>{this.state.currency_conversion_body}</div>
          </div>
        </Modal>

        <Col lg="10">
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Name</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.name}
              onChange={(event) => {
                this.setState({
                  name: event.target.value,
                });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Phone</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.phone}
              onChange={(event) => {
                this.setState({
                  phone: event.target.value,
                });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Town</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.town}
              onChange={(event) => {
                this.setState({
                  town: event.target.value,
                });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Country</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.country}
              onChange={(event) => {
                this.setState({
                  country: event.target.value,
                });
              }}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Email</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.email}
              onChange={(event) => {
                this.setState({
                  email: event.target.value,
                });
              }}
            />
          </InputGroup>
        </Col>
        <Col lg="2">
          <div classname="align-me mb-3">
            <button
              type="button"
              onClick={() => {
                const data = {
                  columns: [
                    {
                      label: "Name",
                      field: "name",
                      sort: "asc",
                      width: 150,
                    },
                    // {
                    //   label: "Address",
                    //   field: "address",
                    //   sort: "asc",
                    //   width: 270,
                    // },
                    {
                      label: "Phone",
                      field: "phone",
                      sort: "asc",
                      width: 200,
                    },
                    {
                      label: "Town",
                      field: "town",
                      sort: "asc",
                      width: 200,
                    },
                    {
                      label: "Country",
                      field: "country",
                      sort: "asc",
                      width: 200,
                    },
                    {
                      label: "Edit",
                      field: "edit",
                      sort: "asc",
                      width: 200,
                    },
                  ],
                  rows: show_to_edit,
                };

                var body = (
                  <div>
                    <MDBDataTable responsive striped bordered data={data} />
                  </div>
                );

                this.setState((prevState) => ({
                  shipper_modal: !prevState.shipper_modal,
                  shipper_body: body,
                }));
              }}
              className="btn btn-success waves-effect waves-light"
            >
              <i className="bx bx-search font-size-16 align-middle mr-2"></i>
              Search
            </button>
          </div>

          <div classname="align-me mb-3" style={{ paddingTop: 10 }}>
            <div>
              <p style={{ fontWeight: "bold" }}>
                {conversion_selected.base_currency} to{" "}
                {conversion_selected.quote_currency} @{" "}
                {conversion_selected.rate}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  tab2() {
    const {
      old_consignee_data,

      // country,
      // town,
      // phone,
      // address,
      // name,

      consignee_delivery,
      consignee_country,
      consignee_town,
      consignee_phone,
      // consignee_address,
      consignee_name,
      consignee_body,
      // consignee_modal,
      // consignee_uid,
      rates_data_extracted,
    } = this.state;
    var export_data = [];
    var show_to_edit = [];

    var list_of_destinations = [];

    if (rates_data_extracted.rates_by_destination !== undefined) {
      var rates_by_destination = _.map(
        rates_data_extracted.rates_by_destination,
        (val, uid) => {
          return { ...val, uid };
        }
      );

      rates_by_destination.sort((a, b) => {
        if (a.city < b.city) {
          return -1;
        }
        if (a.city > b.city) {
          return 1;
        }
        return 0;
      });

      rates_by_destination.forEach((element) => {
        list_of_destinations.push(
          <option value={element.city}>{element.city}</option>
        );
      });
    }

    if (old_consignee_data.length !== 0) {
      export_data.push([
        "Name",

        //"Address",

        "Phone",
        "Town",
        "Country",
      ]);
      old_consignee_data.forEach((element) => {
        export_data.push([
          element.name,
          // element.address,
          element.phone,
          element.town,
          element.country,
          element.delivery,
        ]);

        show_to_edit.push({
          consignee_name: element.name,
          // consignee_address: element.address,
          consignee_phone: element.phone,
          consignee_town: element.town,
          consignee_country: element.country,
          consignee_delivery: element.delivery,
          edit: (
            <div>
              <Row>
                <Col lg="12" className="text-center">
                  <Link
                    onClick={() => {
                      this.setState({
                        // consignee_address: element.address,
                        consignee_country: element.country,
                        consignee_name: element.name,
                        consignee_phone: element.phone,
                        consignee_town: element.town,
                        consignee_uid: element.uid,
                        consignee_delivery: element.delivery,
                        show_old_data: true,
                        consignee_modal: false,
                      });
                    }}
                    className="mr-3 text-primary text-center"
                  >
                    <i
                      className="mdi mdi-pencil font-size-18 "
                      id="edittooltip"
                    ></i>
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </Col>
              </Row>
            </div>
          ),
        });
      });
    }

    return (
      <Row>
        <Modal
          isOpen={this.state.consignee_modal}
          size="lg"
          toggle={() => {
            this.setState((prevState) => ({
              consignee_modal: !prevState.consignee_modal,
            }));
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              List Of All Consignees
            </h5>
            <button
              type="button"
              onClick={() => this.setState({ consignee_modal: false })}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>{this.state.consignee_body}</div>
          </div>
        </Modal>

        <Col lg="10">
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Name</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.consignee_name}
              onChange={(event) => {
                this.setState({
                  consignee_name: event.target.value,
                });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Phone</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.consignee_phone}
              onChange={(event) => {
                this.setState({
                  consignee_phone: event.target.value,
                });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Town</Label>
            </InputGroupAddon>

            <Input
              className="form-control"
              value={this.state.consignee_town}
              onChange={(event) => {
                var value = event.target.value;
                if (value !== "") {
                  this.setState({
                    consignee_town: value,
                  });
                }
              }}
              type="select"
            >
              <option value="">Select Delivery Town...</option>
              {list_of_destinations}
            </Input>
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Country</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.consignee_country}
              onChange={(event) => {
                this.setState({
                  consignee_country: event.target.value,
                });
              }}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Delivery</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.consignee_delivery}
              onChange={(event) => {
                this.setState({
                  consignee_delivery: event.target.value,
                });
              }}
            />
          </InputGroup>
        </Col>
        <Col lg="2">
          <div classname="align-me mb-3">
            <button
              type="button"
              className="btn btn-success waves-effect waves-light"
              onClick={() => {
                const data = {
                  columns: [
                    {
                      label: "Name",
                      field: "consignee_name",
                      sort: "asc",
                      width: 150,
                    },
                    // {
                    //   label: "Address",
                    //   field: "consignee_address",
                    //   sort: "asc",
                    //   width: 270,
                    // },
                    {
                      label: "Phone",
                      field: "consignee_phone",
                      sort: "asc",
                      width: 200,
                    },
                    {
                      label: "Town",
                      field: "consignee_town",
                      sort: "asc",
                      width: 200,
                    },
                    {
                      label: "Country",
                      field: "consignee_country",
                      sort: "asc",
                      width: 200,
                    },

                    {
                      label: "Delivery",
                      field: "consignee_delivery",
                      sort: "asc",
                      width: 200,
                    },

                    {
                      label: "Edit",
                      field: "edit",
                      sort: "asc",
                      width: 200,
                    },
                  ],
                  rows: show_to_edit,
                };

                var body = (
                  <div>
                    <MDBDataTable responsive striped bordered data={data} />
                  </div>
                );

                this.setState((prevState) => ({
                  consignee_modal: !prevState.consignee_modal,
                  consignee_body: body,
                }));
              }}
            >
              <i className="bx bx-search font-size-16 align-middle mr-2"></i>{" "}
              Search
            </button>
          </div>
        </Col>
      </Row>
    );
  }

  tab3() {
    const { cargo_details, raw_data_full_detail, rates_data_extracted } =
      this.state;

    var ori_cargo_details = raw_data_full_detail.cargo_details;

    var disabled_array = [];

    var rate = 0;
    var total_weight = 0,
      total_quantity = 0;

    cargo_details.forEach((element) => {
      if (element !== undefined) {
        if (element.quantity !== "") {
          total_quantity = total_quantity + parseFloat(element.quantity);
        }
        if (element.weight !== "") {
          total_weight = total_weight + parseFloat(element.weight);
        }
      }
    });

    var rates = {};
    if (rates_data_extracted.rates_by_destination !== undefined) {
      var rates_by_destination = _.map(
        rates_data_extracted.rates_by_destination,
        (val, uid) => {
          return { ...val, uid };
        }
      );

      rates_by_destination.forEach((element) => {
        if (this.state.consignee_town === element.city) {
          rates = element;
        }
      });
    }

    if (total_weight < 50) {
      rate = rates.less_than_50_kg;
    } else if (total_weight >= 50 && total_weight < 100) {
      rate = rates.less_than_100_kg;
    } else {
      rate = rates.over_100_kg;
    }

    return (
      <Row>
        <Col xs="12">
          <table style={{ width: "100%" }}>
            <tbody>
              {cargo_details.map((item, idx) => {
                var cargo_details_quantity = "",
                  cargo_details_weight = "";

                var c_quantity = "",
                  c_description = "",
                  c_weight = "",
                  c_rate = "";

                // var cargo_details_quantity = "",
                //   cargo_details_weight = "";

                if (cargo_details[idx]) {
                  c_quantity = cargo_details[idx].quantity;
                  c_description = cargo_details[idx].description;
                  c_weight = cargo_details[idx].weight;
                  c_rate = cargo_details[idx].rate;

                  if (cargo_details[idx]) {
                    cargo_details_quantity = parseFloat(
                      cargo_details[idx].quantity
                    );

                    cargo_details_weight = parseFloat(
                      cargo_details[idx].weight
                    );
                  }
                  var ori_cargo_details_quantity = "",
                    ori_cargo_details_weight = "";

                  if (ori_cargo_details !== undefined) {
                    if (ori_cargo_details[idx]) {
                      ori_cargo_details_quantity = parseFloat(
                        ori_cargo_details[idx].quantity
                      );

                      ori_cargo_details_weight = parseFloat(
                        ori_cargo_details[idx].weight
                      );
                    }
                  }

                  if (parseFloat(cargo_details_quantity) > 0) {
                    if (cargo_details_quantity !== ori_cargo_details_quantity) {
                      // disabled_array[idx] = false;
                    } else {
                      // disabled_array[idx] = true;
                    }
                  } else {
                    if (parseFloat(cargo_details_weight) > 0) {
                      if (cargo_details_weight !== ori_cargo_details_weight) {
                        // disabled_array[idx] = false;
                      } else {
                        // disabled_array[idx] = true;
                      }
                    }
                  }
                }
                return (
                  <tr id={"addr" + idx} key={idx}>
                    <td>
                      <Form className="repeater" encType="multipart/form-data">
                        <div data-repeater-list="group-a">
                          <Row data-repeater-item>
                            <Col lg="2" className="form-group">
                              <Label htmlFor="name">Quantity</Label>
                              <Input
                                type="number"
                                id="name"
                                name="untyped-input"
                                onChange={(event) => {
                                  var value = event.target.value;
                                  var temp_quantity = this.state.cargo_details;
                                  temp_quantity[idx].quantity = value;
                                  this.setState({
                                    cargo_details: temp_quantity,
                                  });
                                }}
                                value={c_quantity}
                              />
                            </Col>

                            <Col lg="2" className="form-group">
                              <Label htmlFor="email">Description</Label>
                              <Input
                                type="email"
                                id="email"
                                onChange={(event) => {
                                  var value = event.target.value;
                                  var temp_description =
                                    this.state.cargo_details;
                                  temp_description[idx].description = value;
                                  this.setState({
                                    cargo_details: temp_description,
                                  });
                                }}
                                value={c_description}
                              />
                            </Col>

                            <Col lg="2" className="form-group">
                              <Label htmlFor="subject">Weight (KG)</Label>
                              <Input
                                type="number"
                                id="subject"
                                onChange={(event) => {
                                  var value = event.target.value;
                                  var temp_weight = this.state.cargo_details;
                                  temp_weight[idx].weight = value;

                                  temp_weight.forEach((element, p) => {
                                    temp_weight[p].rate = rate;
                                  });
                                  this.setState({
                                    cargo_details: temp_weight,
                                  });
                                }}
                                value={c_weight}
                              />
                            </Col>

                            <Col lg="2" className="form-group">
                              <Label htmlFor="subject">Rate (per KG)</Label>
                              <Input
                                type="number"
                                id="subject"
                                disabled
                                value={rate}
                              />
                            </Col>
                            {idx === 0 ? null : (
                              <Col
                                lg="2"
                                className="form-group align-self-center"
                              >
                                <Button
                                  onClick={(e) => {
                                    var temp_delete = this.state.cargo_details;

                                    temp_delete[idx] = undefined;

                                    var temp_holder = [];
                                    temp_delete.forEach((element) => {
                                      if (element !== undefined) {
                                        temp_holder.push(element);
                                      }
                                    });

                                    this.setState({
                                      cargo_details: temp_holder,
                                    });
                                  }}
                                  color="primary"
                                  className="mt-3"
                                  style={{ width: "100%" }}
                                >
                                  Delete
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Form>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Button
            onClick={() => {
              var temp_rate = this.state.cargo_details;

              var var_new_temp = [];

              temp_rate.forEach((element) => {
                if (element !== undefined) {
                  var_new_temp.push(element);
                }
              });

              temp_rate = var_new_temp;

              temp_rate[Object.keys(temp_rate).length] = {
                quantity: "",
                description: "",
                weight: "",
                rate: "",
              };
              this.setState({
                cargo_details: temp_rate,
              });

              const item = {
                quantity: "",
                description: "",
                weight: "",
                rate: "",
              };

              this.setState({
                rows: [...this.state.rows, item],
              });
            }}
            color="success"
          >
            Add{" "}
          </Button>{" "}
        </Col>
      </Row>
    );
  }
  tab4() {
    return (
      <Row>
        <Col lg="12">
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">HBL Charges</Label>
            </InputGroupAddon>
            <Input
              type="number"
              className="form-control"
              value={this.state.hbl_charges}
              onChange={(event) => {
                this.setState({
                  hbl_charges: event.target.value,
                });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Packing Charges</Label>
            </InputGroupAddon>
            <Input
              type="number"
              className="form-control"
              value={this.state.packing_charges}
              onChange={(event) => {
                this.setState({
                  packing_charges: event.target.value,
                });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Transport Charges</Label>
            </InputGroupAddon>
            <Input
              type="number"
              className="form-control"
              value={this.state.transport_charges}
              onChange={(event) => {
                this.setState({
                  transport_charges: event.target.value,
                });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Miscellaneous Charges</Label>
            </InputGroupAddon>
            <Input
              type="number"
              className="form-control"
              value={this.state.miscellaneous_charges}
              onChange={(event) => {
                this.setState({
                  miscellaneous_charges: event.target.value,
                });
              }}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Remarks</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.remarks_charges}
              onChange={(event) => {
                this.setState({
                  remarks_charges: event.target.value,
                });
              }}
            />
          </InputGroup>
        </Col>
      </Row>
    );
  }
  tab5() {
    const { payments_details, raw_data_full_detail } = this.state;

    var ori_payments_details = raw_data_full_detail.payments_details;

    // var disabled_array = [];
    return (
      <Row>
        <Col xs="12">
          <table style={{ width: "100%" }}>
            <tbody>
              {payments_details.map((item, idx) => {
                var date = "",
                  amount = "",
                  payment_receipt_no = "",
                  mode = "";

                if (payments_details[idx]) {
                  date = payments_details[idx].date;
                  amount = payments_details[idx].amount;
                  mode = payments_details[idx].mode;

                  if (payments_details[idx].payment_receipt_no !== undefined) {
                    payment_receipt_no =
                      payments_details[idx].payment_receipt_no;
                  }

                  var payments_details_amount = parseFloat(amount);

                  var ori_payments_details_amount = "";
                  if (ori_payments_details !== undefined) {
                    if (ori_payments_details[idx]) {
                      ori_payments_details_amount = parseFloat(
                        ori_payments_details[idx].amount
                      );
                    }
                  }

                  if (parseFloat(payments_details_amount) > 0) {
                    if (
                      payments_details_amount !== ori_payments_details_amount
                    ) {
                      // disabled_array[idx] = false;
                    } else {
                      // disabled_array[idx] = true;
                    }
                  }
                }

                return (
                  <tr id={"addr" + idx} key={idx}>
                    <td>
                      <Form className="repeater" encType="multipart/form-data">
                        <div data-repeater-list="group-a">
                          <Row data-repeater-item>
                            <Col lg="3" className="form-group">
                              <Label htmlFor="name">Date</Label>
                              <Input
                                type="date"
                                id="name"
                                name="untyped-input"
                                onChange={(event) => {
                                  var value = event.target.value;
                                  var temp_date = this.state.payments_details;
                                  temp_date[idx].date = value;
                                  this.setState({
                                    payments_details: temp_date,
                                  });
                                }}
                                value={date}
                              />
                            </Col>

                            <Col lg="3" className="form-group">
                              <Label htmlFor="email">Amount</Label>
                              <Input
                                type="amount"
                                id="number"
                                // disabled={disabled_array[idx]}
                                onChange={(event) => {
                                  var value = event.target.value;
                                  var temp_amount = this.state.payments_details;
                                  temp_amount[idx].amount = value;
                                  this.setState({
                                    payments_details: temp_amount,
                                  });
                                }}
                                value={amount}
                                // value={payments_details[idx].amount}
                              />
                            </Col>

                            <Col lg="3" className="form-group">
                              <Label htmlFor="email">Payment Receipt No.</Label>
                              <Input
                                type="amount"
                                id="number"
                                onChange={(event) => {
                                  var value = event.target.value;
                                  var temp_amount = this.state.payments_details;
                                  temp_amount[idx].payment_receipt_no = value;
                                  this.setState({
                                    payments_details: temp_amount,
                                  });
                                }}
                                value={payment_receipt_no}
                              />
                            </Col>

                            <Col lg="3" className="form-group">
                              <Label htmlFor="email">Mode</Label>
                              <Input
                                className="form-control"
                                value={mode}
                                onChange={(event) => {
                                  var value = event.target.value;
                                  var temp_mode = this.state.payments_details;
                                  temp_mode[idx].mode = value;
                                  this.setState({
                                    payments_details: temp_mode,
                                  });
                                }}
                                type="select"
                              >
                                <option value="">Select Mode...</option>
                                <option value="CASH">CASH</option>
                                <option value="BANK">BANK</option>
                                <option value="MPESA">MPESA</option>
                                <option value="STCPAY">STCPAY</option>
                              </Input>
                            </Col>

                            {idx === 0 ? null : (
                              <Col
                                lg="3"
                                className="form-group align-self-center"
                              >
                                <Button
                                  onClick={(e) => {
                                    var temp_delete =
                                      this.state.payments_details;

                                    temp_delete[idx] = undefined;

                                    var temp_holder = [];
                                    temp_delete.forEach((element) => {
                                      if (element !== undefined) {
                                        temp_holder.push(element);
                                      }
                                    });

                                    this.setState({
                                      payments_details: temp_holder,
                                    });
                                  }}
                                  color="primary"
                                  className="mt-3"
                                  style={{ width: "100%" }}
                                >
                                  Delete
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Form>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Button
            onClick={() => {
              var temp_rate = this.state.payments_details;

              var var_new_temp = [];

              temp_rate.forEach((element) => {
                if (element !== undefined) {
                  var_new_temp.push(element);
                }
              });

              temp_rate = var_new_temp;

              temp_rate[Object.keys(temp_rate).length] = {
                amount: "",
                date: "",
              };
              this.setState({
                payments_details: temp_rate,
              });

              const item = {
                amount: "",
                date: "",
              };

              this.setState({
                rows_Payments: [...this.state.rows_Payments, item],
              });
            }}
            color="success"
          >
            Add{" "}
          </Button>{" "}
        </Col>
      </Row>
    );
  }

  tab6() {
    /*
     //
      offload_pcs:"",
      offload_lot_no:"",
      offload_date:"",
      offload_remarks:"",
       //
       delivery_pcs:"",
       delivery_lot_no:"",
       delivery_date:"",
       delivery_remarks:"",
       delivery_date_received_by:"",
       delivery_name_id_no:"",
        */
    return (
      <Row>
        <Col xs="12">
          <table style={{ width: "100%" }}>
            <tbody>
              <Row>
                <Col xs="6">
                  <h5>Offload</h5>
                  <Col lg="12">
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">PCS</Label>
                      </InputGroupAddon>
                      <Input
                        type="number"
                        className="form-control"
                        value={this.state.offload_pcs}
                        onChange={(event) => {
                          this.setState({
                            offload_pcs: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">LOT #</Label>
                      </InputGroupAddon>
                      <Input
                        type="number"
                        className="form-control"
                        value={this.state.offload_lot_no}
                        onChange={(event) => {
                          this.setState({
                            offload_lot_no: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">Date</Label>
                      </InputGroupAddon>
                      <Input
                        type="date"
                        className="form-control"
                        value={this.state.offload_date}
                        onChange={(event) => {
                          this.setState({
                            offload_date: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">Remarks</Label>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.offload_remarks}
                        onChange={(event) => {
                          this.setState({
                            offload_remarks: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Col>

                <Col xs="6">
                  <h5>Delivery</h5>

                  <Col lg="12">
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">PCS</Label>
                      </InputGroupAddon>
                      <Input
                        type="number"
                        className="form-control"
                        value={this.state.delivery_pcs}
                        onChange={(event) => {
                          this.setState({
                            delivery_pcs: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">Lot#</Label>
                      </InputGroupAddon>
                      <Input
                        type="number"
                        className="form-control"
                        value={this.state.delivery_lot_no}
                        onChange={(event) => {
                          this.setState({
                            delivery_lot_no: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">Date</Label>
                      </InputGroupAddon>
                      <Input
                        type="date"
                        className="form-control"
                        value={this.state.delivery_date}
                        onChange={(event) => {
                          this.setState({
                            delivery_date: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">Remarks</Label>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.delivery_remarks}
                        onChange={(event) => {
                          this.setState({
                            delivery_remarks: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">
                          Date Recieved
                        </Label>
                      </InputGroupAddon>
                      <Input
                        type="date"
                        className="form-control"
                        value={this.state.delivery_date_received_by}
                        onChange={(event) => {
                          this.setState({
                            delivery_date_received_by: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Label className="input-group-text">Name ID No</Label>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.delivery_name_id_no}
                        onChange={(event) => {
                          this.setState({
                            delivery_name_id_no: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Col>
              </Row>
            </tbody>
          </table>
        </Col>
      </Row>
    );
  }

  calculation() {
    var total_weight = 0;
    var total_quantity = 0;
    var total_amount_paid = 0;
    var total_price = 0;
    var other_charges = 0;
    var vat_charges = 0;
    var total_charges = 0;
    var total_payments = 0;
    var total_outstanding_balance = 0;
    var rate = 0;
    var {
      rates_data_extracted,
      cargo_details,
      hbl_charges,
      packing_charges,
      transport_charges,
      miscellaneous_charges,
      payments_details,
    } = this.state;

    if (hbl_charges === "") {
      hbl_charges = 0;
    }
    if (packing_charges === "") {
      packing_charges = 0;
    }
    if (transport_charges === "") {
      transport_charges = 0;
    }
    if (miscellaneous_charges === "") {
      miscellaneous_charges = 0;
    }
    other_charges =
      parseFloat(hbl_charges) +
      parseFloat(packing_charges) +
      parseFloat(transport_charges) +
      parseFloat(miscellaneous_charges);

    cargo_details.forEach((element) => {
      if (element !== undefined) {
        if (element.quantity !== "") {
          total_quantity = total_quantity + parseFloat(element.quantity);
        }
        if (element.weight !== "") {
          total_weight = total_weight + parseFloat(element.weight);
        }
      }
    });
    if (payments_details) {
      payments_details.forEach((element) => {
        if (element) {
          if (element.amount !== "") {
            total_payments = total_payments + parseFloat(element.amount);
          }
        }
      });
    }

    var rates = {};
    if (rates_data_extracted.rates_by_destination !== undefined) {
      var rates_by_destination = _.map(
        rates_data_extracted.rates_by_destination,
        (val, uid) => {
          return { ...val, uid };
        }
      );

      rates_by_destination.forEach((element) => {
        if (this.state.consignee_town === element.city) {
          rates = element;
        }
      });
    }

    var rate = 0;
    if (total_weight !== 0) {
      if (total_weight < 50) {
        rate = rates.less_than_50_kg;
      } else if (total_weight >= 50 && total_weight < 100) {
        rate = rates.less_than_100_kg;
      } else {
        rate = rates.over_100_kg;
      }
    }

    var vat = parseFloat(rates_data_extracted.vat),
      total_price = (parseFloat(total_weight) * parseFloat(rate)).toFixed(2);
    var first_part = (parseFloat(total_price) + parseFloat(hbl_charges)) / 100;
    vat_charges = (parseFloat(first_part) * parseFloat(vat)).toFixed(2);
    total_charges = (
      parseFloat(total_price) +
      parseFloat(vat_charges) +
      parseFloat(other_charges)
    ).toFixed(2);
    total_outstanding_balance = (
      parseFloat(total_charges) - parseFloat(total_payments)
    ).toFixed(2);

    other_charges = other_charges.toFixed(2);
    total_payments = total_payments.toFixed(2);

    var base_currency = this.state.conversion_selected.base_currency,
      quote_currency = this.state.conversion_selected.quote_currency,
      conversion_selected_rate = 0;
    if (this.state.conversion_selected) {
      conversion_selected_rate = this.state.conversion_selected.rate;
    }

    var converted_price = (
      parseFloat(total_outstanding_balance) *
      parseFloat(conversion_selected_rate)
    ).toFixed(2);

    return {
      total_weight,
      total_price,
      vat_charges,
      total_charges,
      total_outstanding_balance,
      total_quantity,
      other_charges,
      total_payments,
      quote_currency,
      base_currency,
      conversion_selected_rate,
      converted_price,
    };
  }
  render_details() {
    const {
      total_weight,
      total_price,
      vat_charges,
      total_charges,
      total_outstanding_balance,
      total_quantity,
      other_charges,
      total_payments,
      quote_currency,
      base_currency,
      conversion_selected_rate,
      converted_price,
    } = this.calculation();

    return (
      <div style={{ padding: 30, background: "#ECEFF1", borderRadius: 10 }}>
        <Row>
          <Col lg="6">
            <div className="text-center">
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">
                    {total_quantity} pieces weighing {total_weight} kg
                  </Label>
                </InputGroupAddon>
                <Input
                  readOnly
                  type="text"
                  className="form-control"
                  style={{ background: "#ECEFF1", textAlign: "right" }}
                  value={total_price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                />
              </InputGroup>

              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Other Charges</Label>
                </InputGroupAddon>
                <Input
                  readOnly
                  type="text"
                  className="form-control"
                  style={{ background: "#ECEFF1", textAlign: "right" }}
                  value={other_charges
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                />
              </InputGroup>

              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  {/*  <Label className="input-group-text">15% VAT</Label>*/}
                  <Label className="input-group-text">VAT</Label>
                </InputGroupAddon>
                <Input
                  readOnly
                  type="text"
                  className="form-control"
                  style={{ background: "#ECEFF1", textAlign: "right" }}
                  value={vat_charges
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                />
              </InputGroup>

              {this.state.salesperson_name !== "" ? (
                <InputGroup className="mb-1">
                  <InputGroupAddon addonType="prepend">
                    {/*  <Label className="input-group-text">15% VAT</Label>*/}
                    <Label className="input-group-text">
                      Sales Commission of{" "}
                      {this.state.rates_data_extracted.sales_commission_percent}
                      % based on {total_weight} kg{" "}
                    </Label>
                  </InputGroupAddon>
                  <Input
                    readOnly
                    type="text"
                    className="form-control"
                    style={{ background: "#ECEFF1", textAlign: "right" }}
                    value={(
                      total_price *
                      (this.state.rates_data_extracted
                        .sales_commission_percent /
                        100)
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </InputGroup>
              ) : null}
            </div>
          </Col>

          <Col lg="6">
            <div className="text-center">
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Total Charges</Label>
                </InputGroupAddon>
                <Input
                  readOnly
                  type="text"
                  className="form-control"
                  style={{ background: "#ECEFF1", textAlign: "right" }}
                  value={total_charges
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                />
              </InputGroup>

              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Total Payments</Label>
                </InputGroupAddon>
                <Input
                  readOnly
                  type="text"
                  className="form-control"
                  style={{ background: "#ECEFF1", textAlign: "right" }}
                  value={total_payments
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                />
              </InputGroup>

              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">
                    Outstanding Balance
                  </Label>
                </InputGroupAddon>
                <Input
                  readOnly
                  type="text"
                  className="form-control"
                  style={{ background: "#ECEFF1", textAlign: "right" }}
                  value={total_outstanding_balance
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                />
              </InputGroup>

              {Object.keys(this.state.conversion_selected).length > 0 ? (
                <div>
                  <InputGroup className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <Label className="input-group-text">
                        Outstanding Balance {base_currency} to {quote_currency}
                      </Label>
                    </InputGroupAddon>
                    <Input
                      readOnly
                      type="text"
                      className="form-control"
                      style={{ background: "#ECEFF1", textAlign: "right" }}
                      value={converted_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    />
                  </InputGroup>
                  {
                    //<div style={{ paddingTop: 5 }}>
                    //   <a
                    //     style={{ color: "blue" }}
                    //     type="submit"
                    //     color="primary"
                    //     onClick={() => {
                    //       //
                    //       this.setState({
                    //         conversion_selected: {},
                    //       });
                    //     }}
                    //   >
                    //     Remove Conversion
                    //   </a>
                    // </div>
                  }
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  show_old_data_editable() {
    var data_array = this.state.existing_date;
    var lot_number = [];
    var temp_lot_number = [];
    data_array.forEach((element) => {
      temp_lot_number.push(element.Lot_No);
    });

    var unique = temp_lot_number.filter((v, i, a) => a.indexOf(v) === i);

    unique.forEach((element) => {
      lot_number.push(<option value={element}>{element}</option>);
    });

    const {
      total_weight,
      total_price,
      vat_charges,
      total_charges,
      total_outstanding_balance,
      total_quantity,
      other_charges,
      total_payments,
      quote_currency,
      base_currency,
      conversion_selected_rate,
      converted_price,
    } = this.calculation();

    var calculations = {
      rate_conversion: this.state.conversion_selected,
      total_weight,
      total_price,
      vat_charges,
      total_charges,
      total_outstanding_balance,
      total_quantity,
      other_charges,
      total_payments,
      quote_currency,
      base_currency,
      conversion_selected_rate,
      converted_price,
    };

    const MyDocument = this.show_report();

    const { selected_shipment } = this.state;

    var status = "Processing";
    if (selected_shipment.status !== undefined) {
      status = selected_shipment.status;
    }

    var salesperson_name_list = [];

    return (
      <React.Fragment>
        <div>
          <Row>
            <Col lg="12">
              <Row lg="12">
                <Col lg="9">
                  <h5 className="mb-4">Edit House Bill of Landing</h5>
                </Col>
                <Col lg="3">
                  <h5 className="mb-4">Status: {status}</h5>
                </Col>
              </Row>
              <div className="mt-4">
                <Form encType="multipart/form-data">
                  <div data-repeater-list="group-a">
                    <Row data-repeater-item>
                      <Col lg="3" className="form-group">
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <Label className="input-group-text">LOT NO</Label>
                          </InputGroupAddon>

                          <Input
                            className="form-control"
                            value={this.state.lot_number}
                            onChange={(event) => {
                              var value = event.target.value;
                              this.setState({
                                lot_number: String(value),
                              });
                            }}
                            type="select"
                          >
                            <option value="">Select Lot No.</option>
                            {lot_number}
                          </Input>
                        </InputGroup>
                      </Col>

                      <Col lg="3" className="form-group">
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <Label className="input-group-text">HBL NO</Label>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            className="form-control"
                            value={this.state.hbl_number}
                            readOnly
                          />
                        </InputGroup>
                      </Col>

                      <Col lg="3" className="form-group">
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <Label className="input-group-text">
                              DATE CREATED
                            </Label>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            className="form-control"
                            value={this.state.date_created}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                      <Col lg="3" className="form-group">
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <Label className="input-group-text">
                              INVOICE NO
                            </Label>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            className="form-control"
                            // value={this.state.invoice_number}
                            value={this.state.hbl_number + "-" + "001"}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>

                <Row className="justify-content-end">
                  <Col lg="12">
                    {/* <Nav pills className="bg-light rounded" role="tablist">
                      <NavItem>
                        <p style={{ padding: 5 }}>Sales Commission</p>
                      </NavItem>
                          </Nav>*/}
                    <Row>
                      <Modal
                        // isOpen={this.state.consignee_modal}
                        size="lg"
                        toggle={() => {
                          this.setState((prevState) => ({
                            // consignee_modal: !prevState.consignee_modal,
                          }));
                        }}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                            List Of All Sales People
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ consignee_modal: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div>{this.state.consignee_body}</div>
                        </div>
                      </Modal>

                      <Col lg="10" style={{ paddingTop: 10 }}>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <Label className="input-group-text">
                              Salesperson Name (Optional)
                            </Label>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            className="form-control"
                            contentEditable={false}
                            value={this.state.salesperson_name}
                            onChange={(event) => {
                              var sales_commission_percent =
                                this.state.rates_data_extracted
                                  .sales_commission_percent;

                              this.setState({
                                salesperson_name: event.target.value,

                                sales_commission_percent,
                              });
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col lg="2" style={{ paddingTop: 10 }}>
                        <div classname="align-me mb-3">
                          <button
                            type="button"
                            className="btn btn-success waves-effect waves-light"
                            onClick={() => {
                              const { limited_users_data } = this.props;
                              var data_array = _.map(
                                limited_users_data,
                                (val, uid) => {
                                  return { ...val, uid };
                                }
                              );

                              data_array.forEach((element) => {
                                salesperson_name_list.push({
                                  name: element.name,

                                  select: (
                                    <div>
                                      <Row>
                                        <Col lg="12" className="text-center">
                                          <Link
                                            onClick={() => {
                                              this.setState({
                                                // consignee_address: element.address,
                                                salesperson_name: element.name,

                                                show_old_data: true,
                                                consignee_modal: false,
                                              });
                                            }}
                                            className="mr-3 text-primary text-center"
                                          >
                                            <i
                                              className="mdi mdi-pencil font-size-18 "
                                              id="edittooltip"
                                            ></i>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="edittooltip"
                                            >
                                              Edit
                                            </UncontrolledTooltip>
                                          </Link>
                                        </Col>
                                      </Row>
                                    </div>
                                  ),
                                });
                              });

                              const data = {
                                columns: [
                                  {
                                    label: "Name",
                                    field: "name",
                                    sort: "asc",
                                    width: 150,
                                  },
                                  {
                                    label: "Select",
                                    field: "select",
                                    sort: "asc",
                                    width: 200,
                                  },
                                ],
                                rows: salesperson_name_list,
                              };

                              var body = (
                                <div>
                                  <MDBDataTable
                                    responsive
                                    striped
                                    bordered
                                    data={data}
                                  />
                                </div>
                              );

                              this.setState((prevState) => ({
                                consignee_modal: !prevState.consignee_modal,
                                consignee_body: body,
                              }));

                              /*firebase
                                .database()
                                .ref(`/organization/zaincocargo/salespeople`)
                                .once("value")
                                .then((snapshot) => {
                                  var data_array = _.map(
                                    snapshot.val(),
                                    (val, uid) => {
                                      return { ...val, uid };
                                    }
                                  );

                                  data_array.forEach((element) => {
                                    salesperson_name_list.push({
                                      name: element.name,

                                      select: (
                                        <div>
                                          <Row>
                                            <Col
                                              lg="12"
                                              className="text-center"
                                            >
                                              <Link
                                                onClick={() => {
                                                  this.setState({
                                                    // consignee_address: element.address,
                                                    salesperson_name:
                                                      element.name,

                                                    show_old_data: true,
                                                    consignee_modal: false,
                                                  });
                                                }}
                                                className="mr-3 text-primary text-center"
                                              >
                                                <i
                                                  className="mdi mdi-pencil font-size-18 "
                                                  id="edittooltip"
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target="edittooltip"
                                                >
                                                  Edit
                                                </UncontrolledTooltip>
                                              </Link>
                                            </Col>
                                          </Row>
                                        </div>
                                      ),
                                    });
                                  });

                                  const data = {
                                    columns: [
                                      {
                                        label: "Name",
                                        field: "name",
                                        sort: "asc",
                                        width: 150,
                                      },
                                      {
                                        label: "Select",
                                        field: "select",
                                        sort: "asc",
                                        width: 200,
                                      },
                                    ],
                                    rows: salesperson_name_list,
                                  };

                                  var body = (
                                    <div>
                                      <MDBDataTable
                                        responsive
                                        striped
                                        bordered
                                        data={data}
                                      />
                                    </div>
                                  );

                                  this.setState((prevState) => ({
                                    consignee_modal: !prevState.consignee_modal,
                                    consignee_body: body,
                                  }));
                                })
                                .catch((error) => {
                                  this._handleError(error);
                                  return "No User";
                                });
                                */
                            }}
                          >
                            <i className="bx bx-search font-size-16 align-middle mr-2"></i>{" "}
                            Search
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Nav pills className="bg-light rounded" role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      Shipper's Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggleTab("2");
                      }}
                    >
                      Consignee's Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggleTab("3");
                      }}
                    >
                      Cargo
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggleTab("4");
                      }}
                    >
                      Other
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "5",
                      })}
                      onClick={() => {
                        this.toggleTab("5");
                      }}
                    >
                      Payments
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "6",
                      })}
                      onClick={() => {
                        this.toggleTab("6");
                      }}
                    >
                      Offloaded and Delivered
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab} className="mt-4">
                  <TabPane tabId="1" id="buy-tab">
                    {this.tab1()}
                  </TabPane>
                  <TabPane tabId="2" id="sell-tab">
                    {this.tab2()}
                  </TabPane>
                  <TabPane tabId="3" id="sell-tab">
                    {this.tab3()}
                  </TabPane>
                  <TabPane tabId="4" id="sell-tab">
                    {this.tab4()}
                  </TabPane>
                  <TabPane tabId="5" id="sell-tab">
                    {this.tab5()}
                  </TabPane>
                  <TabPane tabId="6" id="sell-tab">
                    {this.tab6()}
                  </TabPane>
                </TabContent>
              </div>

              <Row className="justify-content-end">
                <Col lg="12">
                  <div className="text-center" style={{ paddingBottom: 10 }}>
                    <div
                      className="row justify-content-center"
                      style={{ paddingBottom: 10 }}
                    >
                      <div style={{ padding: 5, paddingTop: 5 }}>
                        <Button
                          type="submit"
                          color="success"
                          onClick={() => {
                            var quantity = "dummyData",
                              description = "dummyData",
                              rate = "dummyData",
                              weight = "dummyData",
                              amount = "dummyData",
                              date = "dummyData";

                            var name_served_by = "";

                            const {
                              user_details,
                              list_of_limited_access_users,
                            } = this.state;

                            var user_id = firebase.auth().currentUser.uid;
                            var conversion_price =
                              this.state.conversion_selected;

                            const { rates_data_extracted } = this.state;

                            var rate = 0;
                            var total_weight = 0;

                            var rates = {};
                            if (
                              rates_data_extracted.rates_by_destination !==
                              undefined
                            ) {
                              var rates_by_destination = _.map(
                                rates_data_extracted.rates_by_destination,
                                (val, uid) => {
                                  return { ...val, uid };
                                }
                              );

                              rates_by_destination.forEach((element) => {
                                if (
                                  this.state.consignee_town === element.city
                                ) {
                                  rates = element;
                                }
                              });
                            }

                            if (total_weight < 50) {
                              rate = rates.less_than_50_kg;
                            } else if (
                              total_weight >= 50 &&
                              total_weight < 100
                            ) {
                              rate = rates.less_than_100_kg;
                            } else {
                              rate = rates.over_100_kg;
                            }

                            if (this.state.cargo_details !== undefined) {
                              this.state.cargo_details.forEach((element) => {
                                if (element !== undefined) {
                                  quantity = element.quantity;
                                  description = element.description;
                                  weight = element.weight;
                                }
                              });
                            }

                            var date_created_log = moment_time_zone()
                              .tz("Etc/GMT")
                              .format("DD/MM/YYYY");
                            // .format("MM/DD/YYYY"); //moment().format("MM/DD/YYYY");
                            var log_array = [];
                            var payments_details_with_log = [];

                            this.state.payments_details.forEach(
                              (element, i) => {
                                amount = element.amount;
                                date = element.date;
                                var element_with_log = element;

                                if (amount !== "") {
                                  if (
                                    Object.keys(this.state.raw_data_full_detail)
                                      .length > 0
                                  ) {
                                    // var element_with_log = element;
                                    var log = {};
                                    log.comment = "Payment Receipt Created";
                                    log.date = date_created_log;
                                    log.hbl = this.state.hbl_number;
                                    log.lot_no = this.state.lot_number;
                                    log.receipt_no =
                                      this.state.hbl_number + "-001";
                                    // this.state.hbl_number + "-000" + (i + 1);
                                    log.user_id = user_id;

                                    if (
                                      list_of_limited_access_users[user_id] !==
                                      undefined
                                    ) {
                                      log.by =
                                        list_of_limited_access_users[
                                          user_id
                                        ].name;
                                    } else {
                                      log.by = "admin";
                                    }

                                    element_with_log.log = log;

                                    log_array.push(log);

                                    // payments_details_with_log.push(
                                    //   element_with_log
                                    // );
                                    // }
                                  }
                                }
                                payments_details_with_log.push(
                                  element_with_log
                                );
                              }
                            );

                            var data = {};
                            if (this.state.salesperson_name !== "") {
                              var commission = (
                                total_price *
                                (this.state.rates_data_extracted
                                  .sales_commission_percent /
                                  100)
                              ).toFixed(2);
                              var salesperson_name =
                                this.state.salesperson_name;
                              var comission_percent_rate =
                                this.state.rates_data_extracted
                                  .sales_commission_percent;

                              if (commission === undefined) {
                                commission = 1;
                              }
                              if (salesperson_name === undefined) {
                                salesperson_name = "";
                              }
                              if (comission_percent_rate === undefined) {
                                comission_percent_rate = 0;
                              }

                              // if (this.state.salesperson_name === "") {
                              //   data.sales_commission = {
                              //     comission_percent_rate: 1,
                              //     salesperson_name: "",
                              //     commission: "",
                              //   };
                              // }

                              data = {
                                calculations,
                                sales_commission: {
                                  comission_percent_rate,
                                  salesperson_name,
                                  commission,
                                },
                                shipper_details: {
                                  name: this.state.name,
                                  // address: this.state.address,
                                  phone: this.state.phone,
                                  town: this.state.town,
                                  country: this.state.country,
                                  email: this.state.email,
                                },
                                consignee_details: {
                                  consignee_name: this.state.consignee_name,
                                  // consignee_address: this.state.consignee_address,
                                  consignee_phone: this.state.consignee_phone,
                                  consignee_town: this.state.consignee_town,
                                  consignee_country:
                                    this.state.consignee_country,
                                  consignee_delivery:
                                    this.state.consignee_delivery,
                                },
                                cargo_details: this.state.cargo_details,
                                other_charges: {
                                  hbl: this.state.hbl_charges,
                                  packing_charges: this.state.packing_charges,
                                  transport_charges:
                                    this.state.transport_charges,
                                  miscellaneous_charges:
                                    this.state.miscellaneous_charges,
                                  remarks_charges: this.state.remarks_charges,
                                },
                                payments_details: this.state.payments_details,

                                payments_log: payments_details_with_log,
                                conversion_price: conversion_price,
                                offload_delivery: {
                                  //
                                  offload_pcs: this.state.offload_pcs,
                                  offload_lot_no: this.state.offload_lot_no,
                                  offload_date: this.state.offload_date,
                                  offload_remarks: this.state.offload_remarks,
                                  //
                                  delivery_pcs: this.state.delivery_pcs,
                                  delivery_lot_no: this.state.delivery_lot_no,
                                  delivery_date: this.state.delivery_date,
                                  delivery_remarks: this.state.delivery_remarks,
                                  delivery_date_received_by:
                                    this.state.delivery_date_received_by,
                                  delivery_name_id_no:
                                    this.state.delivery_name_id_no,
                                },
                              };
                            } else {
                              data = {
                                calculations,
                                shipper_details: {
                                  name: this.state.name,
                                  // address: this.state.address,
                                  phone: this.state.phone,
                                  town: this.state.town,
                                  country: this.state.country,
                                  email: this.state.email,
                                },
                                consignee_details: {
                                  consignee_name: this.state.consignee_name,
                                  // consignee_address: this.state.consignee_address,
                                  consignee_phone: this.state.consignee_phone,
                                  consignee_town: this.state.consignee_town,
                                  consignee_country:
                                    this.state.consignee_country,
                                  consignee_delivery:
                                    this.state.consignee_delivery,
                                },
                                cargo_details: this.state.cargo_details,
                                other_charges: {
                                  hbl: this.state.hbl_charges,
                                  packing_charges: this.state.packing_charges,
                                  transport_charges:
                                    this.state.transport_charges,
                                  miscellaneous_charges:
                                    this.state.miscellaneous_charges,
                                  remarks_charges: this.state.remarks_charges,
                                },
                                payments_details: this.state.payments_details,

                                payments_log: payments_details_with_log,
                                conversion_price: conversion_price,

                                offload_delivery: {
                                  //
                                  offload_pcs: this.state.offload_pcs,
                                  offload_lot_no: this.state.offload_lot_no,
                                  offload_date: this.state.offload_date,
                                  offload_remarks: this.state.offload_remarks,
                                  //
                                  delivery_pcs: this.state.delivery_pcs,
                                  delivery_lot_no: this.state.delivery_lot_no,
                                  delivery_date: this.state.delivery_date,
                                  delivery_remarks: this.state.delivery_remarks,
                                  delivery_date_received_by:
                                    this.state.delivery_date_received_by,
                                  delivery_name_id_no:
                                    this.state.delivery_name_id_no,
                                },
                              };
                            }

                            var invoice_numbers = [
                              {
                                invoice: this.state.invoice_number,
                                date: moment_time_zone()
                                  .tz("Etc/GMT")
                                  .format("DD/MM/YYYY"),
                                // .format("DD-MM-YYYY"), //moment().format("DD-MM-YYYY"),
                              },
                            ];
                            const { raw_data_full_detail } = this.state;
                            if (
                              raw_data_full_detail.invoice_numbers !== undefined
                            ) {
                              var old_invoice = [];
                              old_invoice =
                                raw_data_full_detail.invoice_numbers;

                              invoice_numbers.forEach((element) => {
                                old_invoice.push(element);
                              });

                              invoice_numbers = old_invoice;
                            }

                            data.invoice_numbers = invoice_numbers;

                            const {
                              hbl_charges,
                              packing_charges,
                              transport_charges,
                              miscellaneous_charges,
                              remarks_charges,

                              consignee_name,
                              // consignee_address,
                              consignee_phone,
                              consignee_town,
                              consignee_country,
                              consignee_delivery,

                              name,
                              // address,
                              phone,
                              town,
                              country,
                              email,
                            } = this.state;

                            var log = {};
                            log.comment = "Shipment Edited";
                            log.date = date_created_log;
                            log.hbl = this.state.hbl_number;
                            log.lot_no = this.state.lot_number;
                            log.user_id = user_id; //user_details.user_id;

                            if (
                              list_of_limited_access_users[user_id] !==
                              undefined
                            ) {
                              // log the action here
                              log.by =
                                list_of_limited_access_users[user_id].name;
                              data.log = log;
                              log_array.push(log);
                              name_served_by =
                                list_of_limited_access_users[user_id].name;

                              var b = log_array[0];
                              log_array[0] = log_array[log_array.length - 1];
                              log_array[log_array.length - 1] = b;

                              log_array.forEach((element) => {
                                firebase
                                  .database()
                                  .ref(
                                    `limited_access_users/zaincocargo_logs/${user_id}`
                                  )
                                  .push(element);
                              });
                            } else {
                              log.by = "admin";
                              name_served_by = "admin";
                            }

                            var payment_receipt_no_empty = false;
                            this.state.payments_details.forEach((element) => {
                              if (
                                element.amount !== "" &&
                                element.payment_receipt_no === "" &&
                                payment_receipt_no_empty === false
                              ) {
                                payment_receipt_no_empty = true;
                                console.log(element);
                              }
                            });

                            if (
                              payment_receipt_no_empty === true ||
                              quantity === "" ||
                              description === "" ||
                              rate === "" ||
                              weight === "" ||
                              hbl_charges === "" ||
                              packing_charges === "" ||
                              transport_charges === "" ||
                              miscellaneous_charges === "" ||
                              // remarks_charges === "" ||
                              consignee_name === "" ||
                              consignee_phone === "" ||
                              consignee_town === "" ||
                              consignee_country === "" ||
                              consignee_delivery === "" ||
                              name === "" ||
                              phone === "" ||
                              town === "" ||
                              country === "" //||
                              // email === ""
                            ) {
                              alert("Please ensure all field are filled.");
                            } else {
                              if (this.state.lot_number === "") {
                                alert(
                                  "Please ensure you select a 'Lot No.' before adding the shipment."
                                );
                              } else {
                                var shipper_array = this.state.old_shipper_data;
                                var consignee_array =
                                  this.state.old_consignee_data;

                                const s_name_check = shipper_array.find(
                                  (o) => o.name === name
                                );

                                const s_phone_check = shipper_array.find(
                                  (o) => o.phone === phone
                                );
                                const s_town_check = shipper_array.find(
                                  (o) => o.town === town
                                );
                                const s_country_check = shipper_array.find(
                                  (o) => o.country === country
                                );
                                const s_email_check = shipper_array.find(
                                  (o) => o.email === email
                                );

                                const c_name_check = consignee_array.find(
                                  (o) => o.name === consignee_name
                                );

                                const c_phone_check = consignee_array.find(
                                  (o) => o.phone === consignee_phone
                                );
                                const c_town_check = consignee_array.find(
                                  (o) => o.town === consignee_town
                                );
                                const c_country_check = consignee_array.find(
                                  (o) => o.country === consignee_country
                                );
                                const c_delivery_check = consignee_array.find(
                                  (o) => o.delivery === consignee_delivery
                                );

                                if (
                                  Object.keys(this.state.conversion_selected)
                                    .length > 0
                                ) {
                                  data.conversion =
                                    this.state.conversion_selected;
                                }

                                if (this.state.salesperson_name === "") {
                                  data.sales_commission = {
                                    comission_percent_rate: 1,
                                    salesperson_name: "",
                                    commission: "",
                                  };
                                }

                                this.setState({
                                  saving_loading_status: true,
                                });
                                firebase
                                  .database()
                                  .ref(
                                    `organization/zaincocargo/shipment_details/${this.state.hbl_number}`
                                  )
                                  .update({
                                    C_Phone: consignee_phone,
                                    C_Town: consignee_town,
                                    Consignee: consignee_name,
                                    Date: this.state.date_created,
                                    Delivery: consignee_delivery,
                                    Lot_No: this.state.lot_number,
                                    No: this.state.hbl_number,
                                    Phone: phone,
                                    Qty: quantity,
                                    S_Town: town,
                                    Shipper: name,
                                    Weight: weight,
                                    full_detail: data,
                                    Email: email,
                                  })
                                  .then(() => {
                                    firebase
                                      .database()
                                      .ref(
                                        `organization/zaincocargo/shipment_details/${this.state.hbl_number}/full_detail/logs`
                                      )
                                      .push(log);
                                    this.setState({
                                      saving_loading_status: false,
                                    });
                                    alert("Successfully Updated!");
                                    this.props.shipping_data_read();
                                  })
                                  .catch(() => {
                                    this.setState({
                                      saving_loading_status: false,
                                    });
                                    alert(
                                      "Error! There was an error, shipment not updated"
                                    );
                                  });
                              }
                            }
                          }}
                        >
                          Update Shipment
                        </Button>
                      </div>

                      <div style={{ padding: 5 }}>
                        <div>
                          {this.state.ready && (
                            <PDFDownloadLink
                              document={MyDocument}
                              fileName="PDF"
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? (
                                  "Loading document..."
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.setState({ ready: false });
                                    }}
                                  >
                                    Download Receipt
                                  </Button>
                                )
                              }
                            </PDFDownloadLink>
                          )}

                          {!this.state.ready && (
                            <Button
                              onClick={() => {
                                var quantity = "dummyData",
                                  description = "dummyData",
                                  weight = "dummyData",
                                  amount = "dummyData",
                                  date = "dummyData";

                                var name_served_by = "";
                                const {
                                  user_details,
                                  list_of_limited_access_users,
                                } = this.state;

                                var user_id = firebase.auth().currentUser.uid;
                                var conversion_price =
                                  this.state.conversion_selected;

                                var total_weight = 0;
                                var total_quantity = 0;
                                var total_amount_paid = 0;

                                if (this.state.cargo_details !== undefined) {
                                  this.state.cargo_details.forEach(
                                    (element) => {
                                      if (element !== undefined) {
                                        total_quantity =
                                          total_quantity +
                                          parseFloat(element.quantity);
                                        total_weight =
                                          total_weight +
                                          parseFloat(element.weight);

                                        quantity = element.quantity;
                                        description = element.description;
                                        rate = element.rate;
                                        weight = element.weight;
                                      }
                                    }
                                  );
                                }

                                const { rates_data_extracted } = this.state;

                                var rate = 0; //= 10;
                                var total_weight = 0;

                                var rates = {};
                                if (
                                  rates_data_extracted.rates_by_destination !==
                                  undefined
                                ) {
                                  var rates_by_destination = _.map(
                                    rates_data_extracted.rates_by_destination,
                                    (val, uid) => {
                                      return { ...val, uid };
                                    }
                                  );

                                  rates_by_destination.forEach((element) => {
                                    if (
                                      this.state.consignee_town === element.city
                                    ) {
                                      rates = element;
                                    }
                                  });
                                }

                                if (total_weight < 50) {
                                  rate = rates.less_than_50_kg;
                                } else if (
                                  total_weight >= 50 &&
                                  total_weight < 100
                                ) {
                                  rate = rates.less_than_100_kg;
                                } else {
                                  rate = rates.over_100_kg;
                                }

                                var new_cargo_details = this.adjust_rate();

                                var date_created_log = moment_time_zone()
                                  .tz("Etc/GMT")
                                  .format("DD/MM/YYYY"); //("MM/DD/YYYY");
                                //.format("MM/DD/YYYY"); //moment().format("MM/DD/YYYY");
                                var log_array = [];
                                var payments_details_with_log = [];

                                this.state.payments_details.forEach(
                                  (element, i) => {
                                    amount = element.amount;
                                    date = element.date;
                                    var element_with_log = element;
                                    if (amount !== "") {
                                      var log = {};
                                      log.comment = "Payment Receipt Created";
                                      log.date = date_created_log;
                                      log.hbl = this.state.hbl_number;
                                      log.lot_no = this.state.lot_number;
                                      log.receipt_no =
                                        this.state.hbl_number + "-001";
                                      // this.state.hbl_number + "-000" + (i + 1);

                                      log.user_id = user_id;

                                      if (
                                        list_of_limited_access_users[
                                          user_id
                                        ] !== undefined
                                      ) {
                                        log.by =
                                          list_of_limited_access_users[
                                            user_id
                                          ].name;
                                      } else {
                                        log.by = "admin";
                                      }

                                      element_with_log.log = log;

                                      log_array.push(log);
                                    }

                                    payments_details_with_log.push(
                                      element_with_log
                                    );
                                  }
                                );

                                var data = {};
                                if (this.state.salesperson_name !== "") {
                                  var commission = (
                                    total_price *
                                    (this.state.rates_data_extracted
                                      .sales_commission_percent /
                                      100)
                                  ).toFixed(2);
                                  var salesperson_name =
                                    this.state.salesperson_name;
                                  var comission_percent_rate =
                                    this.state.rates_data_extracted
                                      .sales_commission_percent;

                                  if (commission === undefined) {
                                    commission = 1;
                                  }
                                  if (salesperson_name === undefined) {
                                    salesperson_name = "";
                                  }
                                  if (comission_percent_rate === undefined) {
                                    comission_percent_rate = 0;
                                  }

                                  data = {
                                    calculations,
                                    sales_commission: {
                                      comission_percent_rate,
                                      salesperson_name,
                                      commission,
                                    },
                                    shipper_details: {
                                      name: this.state.name,
                                      phone: this.state.phone,
                                      town: this.state.town,
                                      country: this.state.country,
                                      email: this.state.email,
                                    },
                                    consignee_details: {
                                      consignee_name: this.state.consignee_name,
                                      consignee_phone:
                                        this.state.consignee_phone,
                                      consignee_town: this.state.consignee_town,
                                      consignee_country:
                                        this.state.consignee_country,
                                      consignee_delivery:
                                        this.state.consignee_delivery,
                                    },
                                    cargo_details: new_cargo_details, //this.state.cargo_details,
                                    other_charges: {
                                      hbl: this.state.hbl_charges,
                                      packing_charges:
                                        this.state.packing_charges,
                                      transport_charges:
                                        this.state.transport_charges,
                                      miscellaneous_charges:
                                        this.state.miscellaneous_charges,
                                      remarks_charges:
                                        this.state.remarks_charges,
                                    },
                                    payments_details: payments_details_with_log,
                                    conversion_price: conversion_price,
                                    offload_delivery: {
                                      //
                                      offload_pcs: this.state.offload_pcs,
                                      offload_lot_no: this.state.offload_lot_no,
                                      offload_date: this.state.offload_date,
                                      offload_remarks:
                                        this.state.offload_remarks,
                                      //
                                      delivery_pcs: this.state.delivery_pcs,
                                      delivery_lot_no:
                                        this.state.delivery_lot_no,
                                      delivery_date: this.state.delivery_date,
                                      delivery_remarks:
                                        this.state.delivery_remarks,
                                      delivery_date_received_by:
                                        this.state.delivery_date_received_by,
                                      delivery_name_id_no:
                                        this.state.delivery_name_id_no,
                                    },
                                  };
                                } else {
                                  data = {
                                    calculations,
                                    shipper_details: {
                                      name: this.state.name,
                                      phone: this.state.phone,
                                      town: this.state.town,
                                      country: this.state.country,
                                      email: this.state.email,
                                    },
                                    consignee_details: {
                                      consignee_name: this.state.consignee_name,
                                      consignee_phone:
                                        this.state.consignee_phone,
                                      consignee_town: this.state.consignee_town,
                                      consignee_country:
                                        this.state.consignee_country,
                                      consignee_delivery:
                                        this.state.consignee_delivery,
                                    },
                                    cargo_details: new_cargo_details, //this.state.cargo_details,
                                    other_charges: {
                                      hbl: this.state.hbl_charges,
                                      packing_charges:
                                        this.state.packing_charges,
                                      transport_charges:
                                        this.state.transport_charges,
                                      miscellaneous_charges:
                                        this.state.miscellaneous_charges,
                                      remarks_charges:
                                        this.state.remarks_charges,
                                    },
                                    payments_details: payments_details_with_log,
                                    conversion_price: conversion_price,
                                    offload_delivery: {
                                      //
                                      offload_pcs: this.state.offload_pcs,
                                      offload_lot_no: this.state.offload_lot_no,
                                      offload_date: this.state.offload_date,
                                      offload_remarks:
                                        this.state.offload_remarks,
                                      //
                                      delivery_pcs: this.state.delivery_pcs,
                                      delivery_lot_no:
                                        this.state.delivery_lot_no,
                                      delivery_date: this.state.delivery_date,
                                      delivery_remarks:
                                        this.state.delivery_remarks,
                                      delivery_date_received_by:
                                        this.state.delivery_date_received_by,
                                      delivery_name_id_no:
                                        this.state.delivery_name_id_no,
                                    },
                                  };
                                }

                                const {
                                  hbl_charges,
                                  packing_charges,
                                  transport_charges,
                                  miscellaneous_charges,
                                  remarks_charges,
                                  consignee_name,
                                  consignee_phone,
                                  consignee_town,
                                  consignee_country,
                                  consignee_delivery,
                                  name,
                                  phone,
                                  town,
                                  country,
                                  email,
                                } = this.state;

                                var log = {};
                                log.comment = "Payment Receipt Created";
                                log.date = date_created_log;
                                log.hbl = this.state.hbl_number;
                                log.lot_no = this.state.lot_number;
                                log.user_id = user_id;

                                if (
                                  list_of_limited_access_users[user_id] !==
                                  undefined
                                ) {
                                  log.by =
                                    list_of_limited_access_users[user_id].name;
                                  data.log = log;
                                  name_served_by =
                                    list_of_limited_access_users[user_id].name;
                                  log_array.push(log);

                                  var b = log_array[0];
                                  log_array[0] =
                                    log_array[log_array.length - 1];
                                  log_array[log_array.length - 1] = b;

                                  log_array.forEach((element) => {
                                    firebase
                                      .database()
                                      .ref(
                                        `limited_access_users/zaincocargo_logs/${user_id}`
                                      )
                                      .push(element);
                                  });
                                } else {
                                  log.by = "admin";
                                  data.log = log;
                                  name_served_by = "admin";
                                }

                                var payment_receipt_no_empty = false;
                                this.state.payments_details.forEach(
                                  (element) => {
                                    if (
                                      element.amount !== "" &&
                                      element.payment_receipt_no === "" &&
                                      payment_receipt_no_empty === false
                                    ) {
                                      payment_receipt_no_empty = true;
                                      console.log(element);
                                    }
                                  }
                                );

                                if (
                                  payment_receipt_no_empty === true ||
                                  quantity === "" ||
                                  description === "" ||
                                  rate === "" ||
                                  weight === "" ||
                                  hbl_charges === "" ||
                                  packing_charges === "" ||
                                  transport_charges === "" ||
                                  miscellaneous_charges === "" ||
                                  // remarks_charges === "" ||
                                  consignee_name === "" ||
                                  consignee_phone === "" ||
                                  consignee_town === "" ||
                                  consignee_country === "" ||
                                  consignee_delivery === "" ||
                                  name === "" ||
                                  phone === "" ||
                                  town === "" ||
                                  country === "" //||
                                  // email === ""
                                ) {
                                  alert("Please ensure all field are filled.");
                                } else {
                                  if (this.state.lot_number === "") {
                                    alert(
                                      "Please ensure you select a 'Lot No.' before adding the shipment."
                                    );
                                  } else {
                                    var shipper_array =
                                      this.state.old_shipper_data;
                                    var consignee_array =
                                      this.state.old_consignee_data;

                                    const s_name_check = shipper_array.find(
                                      (o) => o.name === name
                                    );

                                    const s_phone_check = shipper_array.find(
                                      (o) => o.phone === phone
                                    );
                                    const s_town_check = shipper_array.find(
                                      (o) => o.town === town
                                    );
                                    const s_country_check = shipper_array.find(
                                      (o) => o.country === country
                                    );
                                    const s_email_check = shipper_array.find(
                                      (o) => o.email === email
                                    );

                                    const c_name_check = consignee_array.find(
                                      (o) => o.name === consignee_name
                                    );

                                    const c_phone_check = consignee_array.find(
                                      (o) => o.phone === consignee_phone
                                    );
                                    const c_town_check = consignee_array.find(
                                      (o) => o.town === consignee_town
                                    );
                                    const c_country_check =
                                      consignee_array.find(
                                        (o) => o.country === consignee_country
                                      );
                                    const c_delivery_check =
                                      consignee_array.find(
                                        (o) => o.delivery === consignee_delivery
                                      );

                                    if (
                                      c_name_check === undefined &&
                                      c_phone_check === undefined
                                    ) {
                                      firebase
                                        .database()
                                        .ref(
                                          "organization/zaincocargo/consignee/"
                                        )
                                        .push({
                                          name: consignee_name,
                                          phone: consignee_phone,
                                          town: consignee_town,
                                          country: consignee_country,
                                          delivery: consignee_delivery,
                                        });
                                    }

                                    if (
                                      Object.keys(
                                        this.state.conversion_selected
                                      ).length > 0
                                    ) {
                                      data.conversion =
                                        this.state.conversion_selected;
                                    }

                                    var invoice_numbers = [
                                      {
                                        invoice: this.state.invoice_number,
                                        date: moment_time_zone()
                                          .tz("Etc/GMT")
                                          .format("DD/MM/YYYY"),
                                      },
                                    ];

                                    data.invoice_numbers = invoice_numbers;

                                    var invoice_details = {};

                                    //add if statement here if old data exists then add that old data here
                                    var container_arrival_date = "",
                                      container_storage_date = "",
                                      // penalty_fee = 0,
                                      // penalty_days = 0,
                                      // storage_fee_ksh = 0,
                                      // storage_fee_sr = 0;

                                      /*
                                      penalty_fee,
                              penalty_days,
                              storage_fee_ksh,
                              storage_fee_sr, 
                              
                              */

                                      penalty_fee = this.state.penalty_fee, //data.calculations.penalty_fee, //0,
                                      penalty_days = this.state.penalty_days, //data.calculations.penalty_days, //0,
                                      storage_fee_ksh =
                                        this.state.storage_fee_ksh, //data.calculations.storage_fee_ksh, //0,
                                      storage_fee_sr =
                                        this.state.storage_fee_sr; //data.calculations.storage_fee_sr; //0;

                                    // Data >> full_detail >> calculations
                                    data.calculations.container_arrival_date =
                                      container_arrival_date;
                                    data.calculations.container_storage_date =
                                      container_storage_date;
                                    data.calculations.penalty_fee = penalty_fee;
                                    data.calculations.penalty_days =
                                      penalty_days;
                                    data.calculations.storage_fee_ksh =
                                      storage_fee_ksh;
                                    data.calculations.storage_fee_sr =
                                      storage_fee_sr;
                                    ////

                                    var data_for_reciept = {
                                      C_Phone: consignee_phone,
                                      Email: email,
                                      C_Town: consignee_town,
                                      Consignee: consignee_name,
                                      Date: this.state.date_created,
                                      Delivery: consignee_delivery,
                                      Lot_No: this.state.lot_number,
                                      No: this.state.hbl_number,
                                      Phone: phone,
                                      Qty: quantity,
                                      S_Town: town,
                                      Shipper: name,
                                      Weight: weight,
                                      full_detail: data,
                                    };

                                    var new_cargo_details = this.adjust_rate();

                                    // // data_for_reciept >> full_detail >> calculations
                                    data_for_reciept.full_detail.calculations.container_arrival_date =
                                      container_arrival_date;
                                    data_for_reciept.full_detail.calculations.container_storage_date =
                                      container_storage_date;
                                    data_for_reciept.full_detail.calculations.penalty_fee =
                                      penalty_fee;
                                    data_for_reciept.full_detail.calculations.penalty_days =
                                      penalty_days;
                                    data_for_reciept.full_detail.calculations.storage_fee_ksh =
                                      storage_fee_ksh;
                                    data_for_reciept.full_detail.calculations.storage_fee_sr =
                                      storage_fee_sr;
                                    ///////////

                                    invoice_details = {
                                      full_detail: data,
                                      Email: email,
                                      Lot_No: this.state.lot_number,
                                      s_name:
                                        data_for_reciept.full_detail
                                          .shipper_details.name,
                                      s_town_country:
                                        data_for_reciept.full_detail
                                          .shipper_details.country,
                                      s_phone:
                                        data_for_reciept.full_detail
                                          .shipper_details.phone,

                                      c_town_country:
                                        data_for_reciept.full_detail
                                          .consignee_details.consignee_country,
                                      c_phone:
                                        data_for_reciept.full_detail
                                          .consignee_details.consignee_phone,
                                      c_name:
                                        data_for_reciept.full_detail
                                          .consignee_details.consignee_name,
                                      hbl_number: data_for_reciept.No,
                                      date: data_for_reciept.Date,
                                      invoice_number: this.state.invoice_number,

                                      payments_details:
                                        data_for_reciept.full_detail
                                          .payments_details,
                                      calculations:
                                        data_for_reciept.full_detail
                                          .calculations,
                                      cargo_details: new_cargo_details,
                                      name_served_by,
                                    };

                                    this.setState({
                                      invoice_details,
                                    });

                                    this.setState(
                                      (prevState) => ({
                                        ready: false,
                                      }),
                                      () => {
                                        setTimeout(() => {
                                          this.setState({ ready: true });
                                        }, 1);
                                      }
                                    );
                                  }
                                }
                              }}
                            >
                              Generate Receipt
                            </Button>
                          )}
                        </div>
                      </div>
                      <div style={{ padding: 5 }}>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => {
                            var quantity = "dummyData",
                              description = "dummyData",
                              rate = "dummyData",
                              weight = "dummyData",
                              amount = "dummyData",
                              date = "dummyData";

                            var name_served_by = "";

                            const {
                              user_details,
                              list_of_limited_access_users,
                            } = this.state;

                            var user_id = firebase.auth().currentUser.uid;
                            var conversion_price =
                              this.state.conversion_selected;

                            const { rates_data_extracted } = this.state;

                            var rate = 0;
                            var total_weight = 0;

                            var rates = {};
                            if (
                              rates_data_extracted.rates_by_destination !==
                              undefined
                            ) {
                              var rates_by_destination = _.map(
                                rates_data_extracted.rates_by_destination,
                                (val, uid) => {
                                  return { ...val, uid };
                                }
                              );

                              rates_by_destination.forEach((element) => {
                                if (
                                  this.state.consignee_town === element.city
                                ) {
                                  rates = element;
                                }
                              });
                            }

                            if (total_weight < 50) {
                              rate = rates.less_than_50_kg;
                            } else if (
                              total_weight >= 50 &&
                              total_weight < 100
                            ) {
                              rate = rates.less_than_100_kg;
                            } else {
                              rate = rates.over_100_kg;
                            }

                            if (this.state.cargo_details !== undefined) {
                              this.state.cargo_details.forEach((element) => {
                                if (element !== undefined) {
                                  quantity = element.quantity;
                                  description = element.description;
                                  // rate = element.rate;
                                  weight = element.weight;
                                }
                              });
                            }

                            var date_created_log = moment_time_zone()
                              .tz("Etc/GMT")
                              .format("DD/MM/YYYY");
                            // .format("MM/DD/YYYY"); //().format("MM/DD/YYYY");
                            var log_array = [];
                            var payments_details_with_log = [];

                            this.state.payments_details.forEach(
                              (element, i) => {
                                amount = element.amount;
                                date = element.date;
                                var element_with_log = element;

                                if (amount !== "") {
                                  if (
                                    Object.keys(this.state.raw_data_full_detail)
                                      .length > 0
                                  ) {
                                    // var element_with_log = element;
                                    var log = {};
                                    log.comment = "Payment Receipt Created";
                                    log.date = date_created_log;
                                    log.hbl = this.state.hbl_number;
                                    log.lot_no = this.state.lot_number;
                                    log.receipt_no =
                                      this.state.hbl_number + "-001";
                                    // this.state.hbl_number + "-000" + (i + 1);
                                    log.user_id = user_id;

                                    if (
                                      list_of_limited_access_users[user_id] !==
                                      undefined
                                    ) {
                                      log.by =
                                        list_of_limited_access_users[
                                          user_id
                                        ].name;
                                    } else {
                                      log.by = "admin";
                                    }

                                    element_with_log.log = log;

                                    log_array.push(log);

                                    // payments_details_with_log.push(
                                    //   element_with_log
                                    // );
                                  }
                                  //}
                                }
                                payments_details_with_log.push(
                                  element_with_log
                                );
                              }
                            );

                            var data = {
                              calculations,
                              shipper_details: {
                                name: this.state.name,
                                // address: this.state.address,
                                phone: this.state.phone,
                                town: this.state.town,
                                country: this.state.country,
                                email: this.state.email,
                              },
                              consignee_details: {
                                consignee_name: this.state.consignee_name,
                                // consignee_address: this.state.consignee_address,
                                consignee_phone: this.state.consignee_phone,
                                consignee_town: this.state.consignee_town,
                                consignee_country: this.state.consignee_country,
                                consignee_delivery:
                                  this.state.consignee_delivery,
                              },
                              cargo_details: this.state.cargo_details,
                              other_charges: {
                                hbl: this.state.hbl_charges,
                                packing_charges: this.state.packing_charges,
                                transport_charges: this.state.transport_charges,
                                miscellaneous_charges:
                                  this.state.miscellaneous_charges,
                                remarks_charges: this.state.remarks_charges,
                              },
                              payments_details: this.state.payments_details,

                              payments_log: payments_details_with_log,
                              conversion_price: conversion_price,
                              offload_delivery: {
                                //
                                offload_pcs: this.state.offload_pcs,
                                offload_lot_no: this.state.offload_lot_no,
                                offload_date: this.state.offload_date,
                                offload_remarks: this.state.offload_remarks,
                                //
                                delivery_pcs: this.state.delivery_pcs,
                                delivery_lot_no: this.state.delivery_lot_no,
                                delivery_date: this.state.delivery_date,
                                delivery_remarks: this.state.delivery_remarks,
                                delivery_date_received_by:
                                  this.state.delivery_date_received_by,
                                delivery_name_id_no:
                                  this.state.delivery_name_id_no,
                              },
                            };

                            const {
                              hbl_charges,
                              packing_charges,
                              transport_charges,
                              miscellaneous_charges,
                              remarks_charges,

                              consignee_name,
                              // consignee_address,
                              consignee_phone,
                              consignee_town,
                              consignee_country,
                              consignee_delivery,

                              name,
                              // address,
                              phone,
                              town,
                              country,
                              email,
                            } = this.state;

                            var log = {};
                            log.comment = "Shipment Edited";
                            log.date = date_created_log;
                            log.hbl = this.state.hbl_number;
                            log.lot_no = this.state.lot_number;
                            log.user_id = user_id; //user_details.user_id;

                            if (
                              list_of_limited_access_users[user_id] !==
                              undefined
                            ) {
                              // log the action here
                              log.by =
                                list_of_limited_access_users[user_id].name;
                              data.log = log;
                              log_array.push(log);
                              name_served_by =
                                list_of_limited_access_users[user_id].name;

                              var b = log_array[0];
                              log_array[0] = log_array[log_array.length - 1];
                              log_array[log_array.length - 1] = b;

                              log_array.forEach((element) => {
                                firebase
                                  .database()
                                  .ref(
                                    `limited_access_users/zaincocargo_logs/${user_id}`
                                  )
                                  .push(element);
                              });
                            } else {
                              log.by = "admin";
                              name_served_by = "admin";
                            }

                            var payment_receipt_no_empty = false;
                            this.state.payments_details.forEach((element) => {
                              if (
                                element.amount !== "" &&
                                element.payment_receipt_no === "" &&
                                payment_receipt_no_empty === false
                              ) {
                                payment_receipt_no_empty = true;
                                console.log(element);
                              }
                            });

                            if (
                              payment_receipt_no_empty === true ||
                              quantity === "" ||
                              description === "" ||
                              rate === "" ||
                              weight === "" ||
                              hbl_charges === "" ||
                              packing_charges === "" ||
                              transport_charges === "" ||
                              miscellaneous_charges === "" ||
                              // remarks_charges === "" ||
                              consignee_name === "" ||
                              consignee_phone === "" ||
                              consignee_town === "" ||
                              consignee_country === "" ||
                              consignee_delivery === "" ||
                              name === "" ||
                              phone === "" ||
                              town === "" ||
                              country === "" //||
                              // email === ""
                            ) {
                              alert("Please ensure all field are filled.");
                            } else {
                              if (this.state.lot_number === "") {
                                alert(
                                  "Please ensure you select a 'Lot No.' before adding the shipment."
                                );
                              } else {
                                var shipper_array = this.state.old_shipper_data;
                                var consignee_array =
                                  this.state.old_consignee_data;

                                const s_name_check = shipper_array.find(
                                  (o) => o.name === name
                                );

                                const s_phone_check = shipper_array.find(
                                  (o) => o.phone === phone
                                );
                                const s_town_check = shipper_array.find(
                                  (o) => o.town === town
                                );
                                const s_country_check = shipper_array.find(
                                  (o) => o.country === country
                                );
                                const s_email_check = shipper_array.find(
                                  (o) => o.email === email
                                );

                                const c_name_check = consignee_array.find(
                                  (o) => o.name === consignee_name
                                );

                                const c_phone_check = consignee_array.find(
                                  (o) => o.phone === consignee_phone
                                );
                                const c_town_check = consignee_array.find(
                                  (o) => o.town === consignee_town
                                );
                                const c_country_check = consignee_array.find(
                                  (o) => o.country === consignee_country
                                );
                                const c_delivery_check = consignee_array.find(
                                  (o) => o.delivery === consignee_delivery
                                );

                                if (
                                  Object.keys(this.state.conversion_selected)
                                    .length > 0
                                ) {
                                  data.conversion =
                                    this.state.conversion_selected;
                                }

                                // if (
                                //   data.sales_commission.salesperson_name ===
                                //   undefined
                                // ) {
                                //   data.sales_commission.salesperson_name = "";
                                // }

                                if (this.state.salesperson_name === "") {
                                  data.sales_commission = {
                                    comission_percent_rate: 1,
                                    salesperson_name: "",
                                    commission: "",
                                  };
                                }

                                this.setState({
                                  saving_loading_status: true,
                                });
                                firebase
                                  .database()
                                  .ref(
                                    `organization/zaincocargo/shipment_details/${this.state.hbl_number}`
                                  )
                                  .update({
                                    C_Phone: consignee_phone,
                                    C_Town: consignee_town,
                                    Consignee: consignee_name,
                                    Date: this.state.date_created,
                                    Delivery: consignee_delivery,
                                    Lot_No: this.state.lot_number,
                                    No: this.state.hbl_number,
                                    Phone: phone,
                                    Qty: quantity,
                                    S_Town: town,
                                    Shipper: name,
                                    Weight: weight,
                                    full_detail: data,
                                    Email: email,
                                  })
                                  .then(() => {
                                    firebase
                                      .database()
                                      .ref("email_receipt/")
                                      .push({
                                        invoice: this.state.invoice_number,
                                        hbl_number: this.state.hbl_number,
                                      });
                                    firebase
                                      .database()
                                      .ref(
                                        `organization/zaincocargo/shipment_details/${this.state.hbl_number}/full_detail/logs`
                                      )
                                      .push(log);
                                    // alert("Successfully Updated!");
                                    this.setState({
                                      saving_loading_status: false,
                                    });
                                    alert("Successfully Updated!");
                                    this.props.shipping_data_read();
                                  })
                                  .catch(() => {
                                    this.setState({
                                      saving_loading_status: false,
                                    });
                                    alert(
                                      "Error! There was an error, shipment not updated"
                                    );
                                  });
                              }
                            }
                          }}
                        >
                          Update Shipment & Email Receipt
                        </Button>
                      </div>

                      <div style={{ padding: 5 }}>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              confirm_both: true,
                            });
                          }}
                        >
                          Delete HBL
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {this.render_details()}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  processing_of_search_button() {
    this.fetch();
    // setTimeout(() => {
    //   this.fetch();
    // }, 1000);

    // setTimeout(() => {
    //   {
    //     var show_to_edit = [];

    //     if (this.state.existing_date.length !== 0) {
    //       var array_reversed = this.state.existing_date.reverse();
    //       array_reversed.forEach((element) => {
    //         show_to_edit.push({
    //           C_Phone: element.C_Phone,
    //           Consignee: element.Consignee,
    //           Shipper: element.Shipper,
    //           Lot_No: element.Lot_No,
    //           No: element.No,

    //           edit: (
    //             <div>
    //               <Row>
    //                 <Col lg="12" className="text-center">
    //                   <Link
    //                     onClick={() => {
    //                       var full_detail = element.full_detail;

    //                       if (full_detail !== undefined) {
    //                         var consignee_details =
    //                             full_detail.consignee_details,
    //                           cargo_details = full_detail.cargo_details,
    //                           other_charges = full_detail.other_charges,
    //                           payments_details = full_detail.payments_details,
    //                           shipper_details = full_detail.shipper_details,
    //                           conversion = full_detail.conversion;
    //                         if (conversion) {
    //                           if (Object.keys(conversion).length > 0) {
    //                             this.setState({
    //                               conversion_selected: conversion,
    //                             });
    //                           }
    //                         }

    //                         var calculations = full_detail.calculations;

    //                         var penalty_fee = "",
    //                           penalty_days = "",
    //                           storage_fee_ksh = "",
    //                           storage_fee_sr = "";

    //                         if (calculations.penalty_fee !== undefined) {
    //                           penalty_fee = calculations.penalty_fee;
    //                         }
    //                         if (calculations.penalty_days !== undefined) {
    //                           penalty_days = calculations.penalty_days;
    //                         }
    //                         if (calculations.storage_fee_ksh !== undefined) {
    //                           storage_fee_ksh = calculations.storage_fee_ksh;
    //                         }
    //                         if (calculations.storage_fee_sr !== undefined) {
    //                           storage_fee_sr = calculations.storage_fee_sr;
    //                         }

    //                         this.setState({
    //                           raw_data_full_detail: full_detail,
    //                           cargo_details: cargo_details,

    //                           penalty_fee,
    //                           penalty_days,
    //                           storage_fee_ksh,
    //                           storage_fee_sr,

    //                           consignee_country:
    //                             consignee_details.consignee_country,
    //                           consignee_delivery:
    //                             consignee_details.consignee_delivery,
    //                           consignee_name: consignee_details.consignee_name,
    //                           consignee_phone:
    //                             consignee_details.consignee_phone,
    //                           consignee_town: consignee_details.consignee_town,
    //                           hbl_charges: other_charges.hbl,
    //                           packing_charges: other_charges.packing_charges,
    //                           miscellaneous_charges:
    //                             other_charges.miscellaneous_charges,
    //                           remarks_charges: other_charges.remarks_charges,
    //                           transport_charges:
    //                             other_charges.transport_charges,
    //                           payments_details: payments_details,
    //                           country: shipper_details.country,
    //                           name: shipper_details.name,
    //                           phone: shipper_details.phone,
    //                           town: shipper_details.town,
    //                           email: element.Email,
    //                         });
    //                       } else {
    //                         this.setState({
    //                           cargo_details: [
    //                             {
    //                               quantity: "",
    //                               description: "",
    //                               weight: "",
    //                               rate: "",
    //                             },
    //                           ],
    //                           penalty_fee: "",
    //                           penalty_days: "",
    //                           storage_fee_ksh: "",
    //                           storage_fee_sr: "",

    //                           consignee_country: "",
    //                           consignee_delivery: element.Delivery,
    //                           consignee_name: element.Consignee,
    //                           consignee_phone: element.C_Phone,
    //                           consignee_town: "",
    //                           hbl_charges: "",
    //                           packing_charges: "",
    //                           miscellaneous_charges: "",
    //                           remarks_charges: "",
    //                           transport_charges: "",
    //                           payments_details: [{ amount: "", date: "" }],
    //                           // address: "",
    //                           country: "",
    //                           email: "",
    //                           name: element.Shipper,
    //                           phone: "",
    //                           town: element.S_Town,
    //                         });
    //                       }
    //                       var lot_number = element.Lot_No,
    //                         hbl_number = element.No,
    //                         date_created = element.Date;

    //                       var invoice_number = "";

    //                       this.setState({
    //                         invoice_number,
    //                         selected_shipment: element,
    //                         show_old_data: true,
    //                         modal_2nd_tab: false,
    //                         lot_number,
    //                         hbl_number,
    //                         date_created,
    //                       });

    //                       this.hbl_number_and_date();
    //                     }}
    //                     className="mr-3 text-primary text-center"
    //                   >
    //                     <i
    //                       className="mdi mdi-pencil font-size-18 "
    //                       id="edittooltip"
    //                     ></i>
    //                     <UncontrolledTooltip
    //                       placement="top"
    //                       target="edittooltip"
    //                     >
    //                       Edit Delivery Status
    //                     </UncontrolledTooltip>
    //                   </Link>
    //                 </Col>
    //               </Row>
    //             </div>
    //           ),
    //         });
    //       });
    //     }

    //     const data = {
    //       columns: [
    //         {
    //           label: "HBL",
    //           field: "No",
    //           sort: "asc",
    //           width: 150,
    //         },
    //         {
    //           label: "Lot",
    //           field: "Lot_No",
    //           sort: "asc",
    //           width: 270,
    //         },
    //         {
    //           label: "Shipper",
    //           field: "Shipper",
    //           sort: "asc",
    //           width: 200,
    //         },
    //         {
    //           label: "Consignee",
    //           field: "Consignee",
    //           sort: "asc",
    //           width: 200,
    //         },
    //         {
    //           label: "Consignee Phone",
    //           field: "C_Phone",
    //           sort: "asc",
    //           width: 200,
    //         },

    //         {
    //           label: "Edit",
    //           field: "edit",
    //           sort: "asc",
    //           width: 200,
    //         },
    //       ],
    //       rows: show_to_edit,
    //     };

    //     var body = (
    //       <div>
    //         <MDBDataTable responsive striped bordered data={data} />
    //       </div>
    //     );

    //     this.setState({
    //       modal_2nd_tab: true,
    //       body,
    //     });
    //   }
    // }, 1000);
  }
  show_search_button() {
    return (
      <div className="text-center">
        <p className="text-muted">
          Use button below to search for your existing Shipments to edit or to
          export csv
        </p>
        <button
          type="button"
          onClick={() => {
            this.processing_of_search_button();
          }}
          className="btn btn-primary waves-effect waves-light"
          data-toggle="modal"
          data-target="#myModal"
        >
          Search Existing Shipments
        </button>
      </div>
    );
  }

  show_search_button_top_button() {
    return (
      <div className="text-center">
        <p className="text-muted">
          Use button below to search for your existing Shipments to edit or to
          export csv
        </p>
        <button
          type="button"
          onClick={() => {
            this.processing_of_search_button();
          }}
          className="btn btn-primary waves-effect waves-light"
          data-toggle="modal"
          data-target="#myModal"
        >
          Search Existing Shipments
        </button>
      </div>
    );
  }
  render_modal() {
    return (
      <Modal
        isOpen={this.state.modal_2nd_tab}
        size="lg"
        toggle={() => {
          this.setState((prevState) => ({
            modal_2nd_tab: !prevState.modal_2nd_tab,
          }));
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            List Of All Shipments
          </h5>
          <button
            type="button"
            onClick={() => this.setState({ modal_2nd_tab: false })}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>{this.state.body}</div>
        </div>
      </Modal>
    );
  }

  adjust_rate() {
    const { rates_data_extracted, cargo_details } = this.state;

    //////////////////////////////
    var rate = 0;
    var total_weight = 0,
      total_quantity = 0;

    cargo_details.forEach((element) => {
      if (element !== undefined) {
        if (element.quantity !== "") {
          total_quantity = total_quantity + parseFloat(element.quantity);
        }
        if (element.weight !== "") {
          total_weight = total_weight + parseFloat(element.weight);
        }
      }
    });

    var rates = {};
    if (rates_data_extracted.rates_by_destination !== undefined) {
      var rates_by_destination = _.map(
        rates_data_extracted.rates_by_destination,
        (val, uid) => {
          return { ...val, uid };
        }
      );

      rates_by_destination.forEach((element) => {
        if (this.state.consignee_town === element.city) {
          rates = element;
        }
      });
    }

    if (total_weight < 50) {
      rate = rates.less_than_50_kg;
    } else if (total_weight >= 50 && total_weight < 100) {
      rate = rates.less_than_100_kg;
    } else {
      rate = rates.over_100_kg;
    }
    ////////////////////////////////////////////////////////

    if (rate !== 0) {
      var new_cargo_cargo_details = [];
      if (cargo_details !== undefined) {
        cargo_details.forEach((element) => {
          if (element !== undefined) {
            var edit_element = element;
            edit_element.rate = rate;
            new_cargo_cargo_details.push(edit_element);
          }
        });
      }
      return new_cargo_cargo_details;
    } else {
      return cargo_details;
    }
  }

  table_titles() {
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "black",
          borderBottomWidth: 1,
          alignItems: "center",
          height: 24,
          textAlign: "center",
          fontStyle: "bold",
        }}
      >
        <View
          style={{
            width: "25%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            DESCRIPTION
          </Text>
        </View>
        {/*<Text
          style={{
            textAlign: "center",
            width: "15%",
            borderRightColor: "black", //"#CFD8DC",
            borderRightWidth: 1,
            fontWeight: "bold",
            fontSize: 10,
          }}
        >
          PCS
        </Text>*/}
        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            PCS
          </Text>
        </View>
        {/*} <Text
          style={{
            textAlign: "center",
            width: "15%",
            borderRightColor: "black", // "#CFD8DC",
            borderRightWidth: 1,
            fontWeight: "bold",
            fontSize: 10,
          }}
        >
          WEIGHT
        </Text>*/}
        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            WEIGHT
          </Text>
        </View>

        {/*<Text
          style={{
            textAlign: "center",
            width: "15%",
            borderRightColor: "black", // "#CFD8DC",
            borderRightWidth: 1,
            fontWeight: "bold",
            fontSize: 10,
          }}
        >
          RATE
        </Text>*/}

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            RATE
          </Text>
        </View>

        {/*<Text
          style={{
            textAlign: "center",
            width: "15%",
            borderRightColor: "black", // "#CFD8DC",
            borderRightWidth: 1,
            fontWeight: "bold",
            fontSize: 10,
          }}
        >
          AMOUNT
        </Text>*/}

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            AMOUNT
          </Text>
        </View>

        {/*   <Text
          style={{
            textAlign: "center",
            width: "15%",
            fontWeight: "bold",
            fontSize: 12,
          }}
        >
          REMARKS
        </Text>
*/}

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            REMARKS
          </Text>
        </View>
      </View>
    );
  }

  line_items() {
    const { invoice_details } = this.state;

    var data = [];

    if (invoice_details !== null) {
      if (Object.keys(invoice_details).length > 0) {
        var cargo_details = invoice_details.cargo_details;

        cargo_details.forEach((element, i) => {
          data.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
              key={i}
            >
              <Text
                style={{
                  width: "25%",
                  textAlign: "left",
                  borderBottomColor: "black",
                  borderRightWidth: 1,
                  paddingLeft: 8,
                  fontSize: 9,
                }}
              >
                {element.description}
              </Text>
              <Text
                style={{
                  width: "15%",
                  borderBottomColor: "black",
                  borderRightWidth: 1,
                  textAlign: "center",
                  paddingRight: 8,
                  fontSize: 9,
                }}
              >
                {element.quantity}
              </Text>
              <Text
                style={{
                  width: "15%",
                  borderBottomColor: "black",
                  borderRightWidth: 1,
                  textAlign: "center",
                  paddingRight: 8,
                  fontSize: 9,
                }}
              >
                {element.weight}
              </Text>
              <Text
                style={{
                  width: "15%",
                  textAlign: "center",
                  borderBottomColor: "black",
                  borderRightWidth: 1,
                  paddingRight: 8,
                  fontSize: 9,
                }}
              >
                {element.rate}
              </Text>
              <Text
                style={{
                  width: "15%",
                  textAlign: "center",
                  borderBottomColor: "#000",
                  borderRightWidth: 1,

                  paddingRight: 8,
                  fontSize: 9,
                  paddingLeft: 5,
                }}
              >
                {parseFloat(element.rate) * parseFloat(element.weight)}
              </Text>

              <Text
                style={{
                  width: "14%",
                  textAlign: "left",
                  paddingRight: 8,
                  fontSize: 8,
                  paddingLeft: 5,
                }}
              >
                {/*Add Remarks*/}
              </Text>
            </View>
          );
        });
      }
    }

    return data;
  }

  /**=========================================================================== */

  table_last_line() {
    const { data_for_reciept, invoice_details } = this.state;

    var view_to_show = <View></View>;

    if (invoice_details !== null) {
      if (Object.keys(invoice_details).length > 0) {
        var payments_details = invoice_details.payments_details;
        var calculations = invoice_details.calculations;
        var full_detail = invoice_details.full_detail;

        var payments_showing_array = [];

        payments_details.forEach((element, i) => {
          // moment_time_zone().tz("Etc/GMT").format("MM/DD/YYYY");
          var date_to_show = moment_time_zone(element.date)
            .tz("Etc/GMT")
            .format("DD/MM/YYYY");
          // .format("DD-MM-YYYY"); //moment(element.date).format("DD-MM-YYYY");

          payments_showing_array.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontSize: 12,
                fontStyle: "bold",
              }}
            >
              <Text
                style={{
                  width: "83.75%",
                  textAlign: "right",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingRight: 8,
                  fontSize: 12,
                }}
              >
                Payment #{i + 1}({date_to_show})
              </Text>
              <Text
                style={{
                  width: "16.25%",
                  textAlign: "left",
                  paddingRight: 8,
                  fontSize: 10,
                  paddingLeft: 5,
                }}
              >
                {parseFloat(element.amount).toFixed(2)}
              </Text>
            </View>
          );
        });

        var total_pieces = 0;
        var total_weight = 0;
        var rate = 0;

        if (full_detail.cargo_details !== undefined) {
          full_detail.cargo_details.forEach((element) => {
            if (element !== undefined) {
              if (element.quantity !== "") {
                total_pieces = total_pieces + parseFloat(element.quantity);
              }
              if (element.weight !== "") {
                total_weight = total_weight + parseFloat(element.weight);
              }

              rate = element.rate;
            }
          });
        }

        var total_outstanding_balance_with_fees = 0,
          total_outstanding_balance_with_fees_kse = 0;

        var storage_fee_sr = "0",
          penalty_fee = "0";
        if (full_detail.calculations.storage_fee_sr !== undefined) {
          if (full_detail.calculations.storage_fee_sr !== "") {
            storage_fee_sr = full_detail.calculations.storage_fee_sr;
          }
        }

        if (full_detail.calculations.penalty_fee !== undefined) {
          //do this
          if (full_detail.calculations.penalty_fee !== "") {
            penalty_fee = full_detail.calculations.penalty_fee;
          }
        }

        //
        total_outstanding_balance_with_fees =
          parseFloat(full_detail.calculations.total_outstanding_balance) +
          parseFloat(storage_fee_sr) +
          parseFloat(penalty_fee); //.toFixed(2);

        total_outstanding_balance_with_fees_kse =
          parseFloat(full_detail.calculations.converted_price) +
          parseFloat(storage_fee_sr) *
            full_detail.calculations.conversion_selected_rate +
          parseFloat(penalty_fee) *
            full_detail.calculations.conversion_selected_rate; //.toFixed(2);

        total_outstanding_balance_with_fees_kse =
          total_outstanding_balance_with_fees_kse.toFixed(2);
        total_outstanding_balance_with_fees_kse =
          total_outstanding_balance_with_fees_kse
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        total_outstanding_balance_with_fees =
          total_outstanding_balance_with_fees.toFixed(2);

        total_outstanding_balance_with_fees =
          total_outstanding_balance_with_fees
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        view_to_show = (
          <View>
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_pieces}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_weight}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {rate}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(full_detail.calculations.total_price).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  -
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  VAT
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.vat_charges}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL FEES
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(full_detail.other_charges.hbl).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  PACKING
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.packing_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TRANSPORT
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.transport_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  MISCELLANEOUS
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.miscellaneous_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  PENALTY
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.penalty_fee}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  STORAGE
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.storage_fee_sr}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                {full_detail.calculations.storage_fee_sr === 0 ? (
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {full_detail.calculations.penalty_days} DAYS
                  </Text>
                )}
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL PAYABLE SR.
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance_with_fees}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  KSH
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",

                  backgroundColor: "#E0E0E0",

                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.conversion_selected_rate}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance_with_fees_kse}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>
          </View>
        );

        //
      }
    }

    return view_to_show;
  }

  company_details() {
    return (
      <View
        style={{
          paddingTop: 5,
          paddingTop: 5,
          flexDirection: "row",
          flexWrap: "wrap",
          borderWidth: 1,
          borderColor: "#FFF",
        }}
      >
        <View
          style={{
            // width: "50%",
            width: "100%",
            // textAlign: "left",
            borderWidth: 1,
            borderColor: "#000",
          }}
        >
          <View style={{ padding: 5 }}>
            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 125,
                }}
                src={logo}
              />
            </View>

            <Text style={{ fontSize: 10, textAlign: "center" }}>
              info@zaincocargo.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              www.zaincocargo.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              Jeddah: 053-2305902, 055-4577423 || Riyadh: 050-3682739,
              055-4925017, 055-3366479 || Dammam: 050-3670629, 054-8337045 ||
              Nairobi: 0721-243800, 0726-376253|| Mombasa: 041-2228393,
              041-2228064
            </Text>
          </View>
        </View>
      </View>
    );
  }
  shipper_consignee_details({ shipper_details, consignee_details }) {
    return (
      <View
        style={{
          // paddingTop: 10,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "50%",
          }}
        >
          <View>{shipper_details}</View>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            borderLeftColor: "white",
            width: "50%",
          }}
        >
          <View>{consignee_details}</View>
        </View>
      </View>
    );
  }

  hbl_details(invoice_details) {
    //invoice_details
    /*
  if (element.status === undefined) {
          status = "Processing";
  }

           */

    const { selected_shipment } = this.state;

    var status = "Processing";
    if (selected_shipment.status !== undefined) {
      status = selected_shipment.status;
    }

    var closing_date = "";
    if (invoice_details !== null) {
      this.state.old_container_data.forEach((element) => {
        if (invoice_details.Lot_No === element.lot) {
          closing_date = element.closing_date;
        }
      });
    }
    return (
      <View>
        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  LOT NO.
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  STATUS
                </Text>
                {/*} <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  CLOSING
                </Text>*/}
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                {invoice_details === null ? (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {invoice_details.hbl_number}
                  </Text>
                )}
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                {invoice_details === null ? (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {invoice_details.date}
                  </Text>
                )}
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                {invoice_details === null ? (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {invoice_details.Lot_No}
                  </Text>
                )}
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                {invoice_details === null ? (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {status /*closing_date*/}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  payment_details(invoice_details) {
    var payment_details = [];

    var invoice_numbers = [];
    var show_payment_details = [];
    if (invoice_details !== null) {
      if (invoice_details.full_detail !== undefined) {
        payment_details = invoice_details.full_detail.payments_details;

        invoice_numbers = invoice_details.full_detail.invoice_numbers;
      }

      if (payment_details.length > 0) {
        // var temp_invoice_num = "";
        var temp_invoice_num = this.state.hbl_number + "-001"; //"";

        payment_details.forEach((element, i) => {
          var formated_amount = "-";
          var formated_date = "-";

          var show_invoice_number = "";
          if (element.payment_receipt_no !== undefined) {
            show_invoice_number = element.payment_receipt_no;
            // console.log({ show_invoice_number });
          }

          if (element.date !== "") {
            formated_date = element.date;
          }
          if (element.amount !== "") {
            formated_amount = parseFloat(element.amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          // if (invoice_numbers[i] !== undefined) {
          //   temp_invoice_num = invoice_numbers[i].invoice;
          // }

          show_payment_details.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {i + 1}
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {show_invoice_number}
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {formated_date}
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {formated_amount}
                </Text>
              </View>
            </View>
          );
        });
      }
    }
    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              PAYMENT DETAILS
            </Text>
          </View>
        </View>

        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  No.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  PAYMENT RECEIPT NO.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  AMOUNT
                </Text>
              </View>
            </View>

            {show_payment_details}
          </View>
        </View>
      </View>
    );
  }

  contact_details() {
    // const VAT_NO = "103220002236175 || ۱۰۳۲۲۰۰۰۲۲۳٦۱۷٥"; //"010004185494";
    const VAT_NO = "311244697700003";
    const MOBILE = "+966505691673";
    /*
      <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              MOBILE {MOBILE} JEDDAH, KING KHALID STREET VAT NO.
              {VAT_NO}
            </Text>
             */

    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                height: 45,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "50%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  VAT NO. {VAT_NO}
                </Text>
              </View>

              <View
                style={{
                  width: "50%",
                }}
              >
                <View
                  style={{
                    paddingBottom: 5,
                    paddingTop: 5,
                    alignItems: "right",
                  }}
                >
                  <Image
                    style={{
                      width: 150,
                    }}
                    src={vat_arabic}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );

    /*
    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              VAT NO. {VAT_NO}
            </Text>

            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 150,
                }}
                src={vat_arabic}
              />
            </View>
          </View>
        </View>
      </View>
    );
    */
  }

  total_charges_payments_outstanding(invoice_details) {
    if (invoice_details !== null) {
      var full_detail = invoice_details.full_detail;

      var converted_total_charges = 0,
        converted_total_payments = 0,
        converted_total_outstanding = 0,
        total_charges = 0,
        total_outstanding_balance = 0,
        total_payments = 0;
      if (full_detail !== undefined) {
        //////////////////

        var storage_fee_sr = "0",
          penalty_fee = "0";
        if (full_detail.calculations.storage_fee_sr !== undefined) {
          if (full_detail.calculations.storage_fee_sr !== "") {
            storage_fee_sr = full_detail.calculations.storage_fee_sr;
          }
        }

        if (full_detail.calculations.penalty_fee !== undefined) {
          if (full_detail.calculations.penalty_fee !== "") {
            penalty_fee = full_detail.calculations.penalty_fee;
          }
        }
        ///

        total_payments = full_detail.calculations.total_payments
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        total_charges = (
          parseFloat(full_detail.calculations.total_charges) +
          parseFloat(storage_fee_sr) +
          parseFloat(penalty_fee)
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        var conversion_factor = parseFloat(
          full_detail.calculations.conversion_selected_rate
        );
        converted_total_charges = (
          parseFloat(full_detail.calculations.total_charges) *
            conversion_factor +
          parseFloat(storage_fee_sr) * conversion_factor +
          parseFloat(penalty_fee) * conversion_factor
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        converted_total_payments = (
          parseFloat(full_detail.calculations.total_payments) *
          full_detail.calculations.conversion_selected_rate
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        total_outstanding_balance = (
          parseFloat(full_detail.calculations.total_outstanding_balance) +
          (parseFloat(storage_fee_sr) + parseFloat(penalty_fee))
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        converted_total_outstanding = (
          parseFloat(full_detail.calculations.total_outstanding_balance) *
            full_detail.calculations.conversion_selected_rate +
          (parseFloat(storage_fee_sr) *
            full_detail.calculations.conversion_selected_rate +
            parseFloat(penalty_fee) *
              full_detail.calculations.conversion_selected_rate)
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      return (
        <View style={{ paddingTop: 15 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  SAUDI RIYALS
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  KENYA SHILLINGS
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL CHARGES
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_charges}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {converted_total_charges}
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL PAYMENTS
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_payments}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {converted_total_payments}
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                  backgroundColor: "#E0E0E0",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  OUTSTANDING
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                  backgroundColor: "#E0E0E0",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                  backgroundColor: "#E0E0E0",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {converted_total_outstanding}
                </Text>
              </View>
            </View>
          </View>
        </View>
      );
    } else {
      return <View></View>;
    }
  }

  banking_details() {
    const RAJHI_BANK_ACCOUNT =
      "158608010260172 IBAN  SA05 8000 0158 6080 10260172";
    const NCB_BANK_ACCOUNT =
      "75400001323509 IBAN SA56 1000 0075 4000 0132 3509";
    const INMA_BANK_ACCOUNT = "####";
    const GULF_AFRICAN_BANK = "0330010801";
    const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    const STCPAY = "0505691673 Zainco Cargo Services"; //"0555691673 TURKY AL ZAHRANI";
    const VAT_NO = "311244697700003";
    const MOBILE = "+966505691673";
    //
    var rajhi_bank_account = RAJHI_BANK_ACCOUNT;
    var ncb_bank_account = NCB_BANK_ACCOUNT;
    var inma_bank_account = INMA_BANK_ACCOUNT;
    var gulf_african_bank = GULF_AFRICAN_BANK;
    var mpesa = MPESA;
    var stcpay = STCPAY;
    var vat_no = VAT_NO;
    var mobile = MOBILE;
    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                RAJHI BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {rajhi_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                NCB BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {ncb_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                INMA BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {inma_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                GULF AFRICAN BANK
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              {/*<Text style={{ fontSize: 11, paddingLeft: 5 }}>0330010801</Text>*/}
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {gulf_african_bank}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                MPESA
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              {/* <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                PAYBILL 985050 ACCOUNT 0130002301
              </Text>*/}
              <Text style={{ fontSize: 11, paddingLeft: 5 }}> {mpesa} </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5 }}>STCPAY</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5 }}>
              {/* <Text style={{ fontSize: 11 }}>0555691673 TURKY AL ZAHRANI</Text>*/}
              <Text style={{ fontSize: 11 }}>{stcpay}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  desclaimer_other_details() {
    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>1.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",

              width: "95%",
              borderLeftColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Freight must be paid upfront during pickup of the packages or
                before arrival at ZAINCO warehouse Mombasa. A penalty of Sr 50
                will be levied on any HBL that has outstanding freight.
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderTopColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>2.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "95%",
              borderTopColor: "white",
              borderLeftColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Free time of 10 days from the date the packages arrives Mombasa
                warehouse, after which a tariff of Ksh. 1.50 or equivalent to
                0.0536 Saudi Riyals per kilo per day is applicable upto and
                until the day all charges are paid in full.
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  show_report() {
    var details = {};
    const { data_for_reciept, invoice_details } = this.state;

    var s_name = "",
      s_address = "",
      s_town_country = "",
      s_phone = "",
      c_address = "",
      c_town_country = "",
      c_phone = "",
      c_name = "",
      hbl_number = "",
      date = "",
      invoice_number = "",
      c_delivery_town = "",
      s_town = "";

    if (invoice_details !== null) {
      var size = Object.keys(invoice_details).length;

      if (size > 0) {
        s_name = invoice_details.s_name;
        s_town_country = invoice_details.s_town_country;
        s_phone = invoice_details.s_phone;
        c_town_country = invoice_details.c_town_country;
        c_phone = invoice_details.c_phone;
        c_name = invoice_details.c_name;
        hbl_number = invoice_details.hbl_number;
        date = invoice_details.date;
        invoice_number = invoice_details.invoice_number;
        s_town = invoice_details.full_detail.shipper_details.town;
        c_delivery_town =
          invoice_details.full_detail.consignee_details.consignee_delivery;
        //213569
      }
    }
    var title = "ZAINCO INVOICE";
    var shipper_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,

            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Shipper Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{s_name}</Text>
        <Text style={{ fontSize: 10 }}>{s_town}</Text>
        <Text style={{ fontSize: 10 }}>{s_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{s_phone}</Text>
      </View>
    );

    var consignee_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,
            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Consignee Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{c_name}</Text>
        <Text style={{ fontSize: 10 }}>{c_delivery_town}</Text>
        <Text style={{ fontSize: 10 }}>{c_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{c_phone}</Text>
      </View>
    );

    var table_titles = this.table_titles();

    var line_items = this.line_items();

    var table_last_line = this.table_last_line();

    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          <View>{this.company_details()}</View>

          <View style={{ paddingTop: 10 }}>
            {this.hbl_details(invoice_details)}
          </View>

          <View style={{ paddingTop: 10 }}>
            {this.shipper_consignee_details({
              shipper_details,
              consignee_details,
            })}
          </View>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: 24,
              borderWidth: 1,
              borderColor: "black",
            }}
          >
            {table_titles}

            {line_items}
            {table_last_line}
          </View>
          <View style={{ paddingTop: 10 }}>
            {this.payment_details(invoice_details)}
            {this.total_charges_payments_outstanding(invoice_details)}

            <View style={{ paddingTop: 10 }}>
              {this.contact_details()}
              {this.banking_details()}
              {this.desclaimer_other_details()}
            </View>
          </View>
        </Page>
        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          {this.terms_and_conditions()}
        </Page>
      </Document>
    );
  }
  terms_and_conditions() {
    var terms = [
      "Packages and Items to be shipped must be well packed and have to be in good condition and labelled all contacts. Shipper agrees. to make him/her responsible for the standard of packing. In case packing done by ZAINCO staff the company does not take any responsibility for the packing. All fragile materials must be packed with Styrofoam.",
      "Fragile Items such as Electronics, Cutlery, Home Appliances etc. in case of breakage ZAINCO will not take any responsibility whatso ever and will not compensate. Wooden box is a must for all home appliance to safe your goods.",
      "Cash, Gold, Ammunitions, Dangerous goods, Chemicals etc are not allowed at any time. Goods with fluids must be indicated clearly by shipper and in the case of damages caused by goods shipper/consignee will be liable for all the damages caused.",
      "ZAINCO will deliver the aforesaid shipment to your door step as they were received not knowing either the contents or nature of the goods.",
      "ZAINCO is authorized by shipper to open any package(s) if requested by government authorities at any time.",
      "In the event of any package(s) delivered opened or unpacked due to routine customs verification the consignee s the right to refuse and returned on the spot, if accepted to collect weather not knowing the contents, ZAINCO will not accept any claim after that. ZAINCO will confirm the weight of the package as per system in case of any difference in weight the consignee will be compensated as per item no. (7) The claim must be forwarded by Shipper / Consignee by email immediately to the Head Office (zainkhitamy@gmail.com) verbal claim will not be entertain at all. Any claims will be dissolving as per our tariff.",
      "ZAINCO will compensate the shipper in case of lost package (s) or part of, with amount not exceeding2OO% of the shipping freight of lost package on prorata bases on cost of freight paid to ZAINCO or declared value which ever is less. In the event of commercial commodity compensation will not exceed USD 200 per package not less than 25 Kgs. ",
      "Freight charges must be paid upfront prior departure. Shipment will not be release if freight charges have not been paid in full, Ten Days is allowed as free time from the date your package (s) land in our warehouse after which storage charges will apply as per our tariff KSH. 1.50 Per kilo per day payable in Kenya prior release. And a penalty of equivalent to SR. 50/= will be charges in the event freight has not been fully paid and the shipment has arrived at our Mombasa warehouse.",
      "In the event of currency fluctuation, current market rate will be applied when making payment.",
      "ZAINCO has the right to auction your shipment at a force value to recover its freight and storage charges if the package not paid after 30 days from the date of arrival at our warehouse without giving any notice to the shipper or consignee.",
      "ZAINCO will not be responsible of any claim in case of civil unrest, mob destruction, anarchy, calamities etc. which may result in looting.",
      "Transit period is one month from the day the vessel departs Jeddah Port to arrival at our warehouse. ZAINCO is NOT responsible in the event of any delay caused by Shipping Line, Customs authority at departing port, or Custom authority at Port of destination but will make sure safe arrival of your cargo and ZAINCO will not be responsible for the loss of revenue due to the delay.",
      "ZAINCO reserves the right to confiscate all restricted/prohibited any point of transit without further warning to Shipper / Consignee. In such unlikely event that the restricted/prohibited consignments are shipped, ZAINCO will take legal action against the shipper and consignee. The Shipper/ Consignee will bear full costs of freight, Port charges, demurrage, transport expenses, Customs penalties, legal fees incurred etc",
      "ZAINCO is not a MUST to notify Shipper/Consignee upon arrival of his/her shipment and storage charges must be paid in case of freight not fully paid, no any excuse will be entertain.",
      "The above terms and conditions are fully effective for all packages and shipment carried.",
    ];

    var terms_array_show = [];
    terms.forEach((element, i) => {
      terms_array_show.push(
        <View
          style={{
            flexDirection: "row",
            borderColor: "white",
            flexWrap: "wrap",
            paddingTop: 10,
            // paddingBottom: 10,
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ fontSize: 10 }}>
              {i + 1}. {element}
            </Text>
          </View>
        </View>
      );
    });
    return (
      <View>
        <Text
          style={{
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Terms and Conditions
        </Text>

        {terms_array_show}
      </View>
    );
  }

  delete_hbl() {
    firebase
      .database()
      .ref(
        `organization/zaincocargo/shipment_details/${this.state.hbl_number}/`
      )
      .remove()
      .then(() => {
        alert("HBL has successfully been removed!");

        this.setState({
          startDate: new Date(),
          endDate: new Date(),
          activeTab: "1",
          activeTab_MAIN_NAV: "1",
          inputFields: [{ name: "", file: "" }],

          selected_shipment: {},
          show_old_data: false,
          modal_2nd_tab: false,
          consignee_delivery: "",
          consignee_country: "",
          consignee_town: "",
          consignee_phone: "",
          consignee_name: "",
          consignee_modal: false,
          consignee_body: <div></div>,
          consignee_uid: "",
          country: "",
          town: "",
          phone: "",
          name: "",
          email: "",
          shipper_modal: false,
          shipper_body: <div></div>,
          uid: "",
          modal_2nd_tab: false,

          currency_conversion_modal: false,
          currency_conversion_body: <div></div>,

          cargo_details: [
            {
              quantity: "",
              description: "",
              weight: "",
              rate: "",
            },
          ],
          payments_details: [{ amount: "", date: "" }],

          rows: [],

          rows_Payments: [],

          remarks_charges: "",
          miscellaneous_charges: "",
          transport_charges: "",
          packing_charges: "",
          hbl_charges: "",

          hbl_number: "",
          lot_number: "",
          date_created: "",
          array_for_all_HBL_numbers: [],

          raw_data_full_detail: {},
          invoice_number: "",
          invoice_details: {},
          ready: false,
        });

        this.setState({
          confirm_both: false,
        });
      })
      .catch(() => {
        alert("Oops! There was an error. Please try again!");

        this.setState({
          confirm_both: false,
        });
      });

    this.fetch();
  }

  render() {
    const { show_old_data, saving_loading_status } = this.state;

    if (saving_loading_status === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }

    if (show_old_data === true) {
      return (
        <div>
          {this.state.confirm_both ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={async () => {
                /******* */
                this.delete_hbl();
              }}
              onCancel={() =>
                this.setState({
                  confirm_both: false,
                })
              }
            ></SweetAlert>
          ) : null}

          {this.render_modal()}
          <Row>
            <Col lg="12">{this.show_search_button_top_button()}</Col>
          </Row>
          {this.show_old_data_editable()}
        </div>
      );
    }
    return (
      <div>
        {this.state.confirm_both ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={async () => {
              /******* */
              this.delete_hbl();
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
              })
            }
          ></SweetAlert>
        ) : null}

        {this.render_modal()}
        <Row>
          <Col lg="12">{this.show_search_button()}</Col>
        </Row>
      </div>
    );
  }
}

// export default TasksCreate;
const mapStatetoProps = (state) => {
  const {
    user_details,
    error,
    loading,
    shipping_data,
    container_data,
    currency_data,
    rates_data,
    limited_users_data,
  } = state.Database;

  return {
    user_details,
    error,
    loading,
    shipping_data,
    container_data,
    currency_data,
    rates_data,
    limited_users_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    shipping_data_read,
    container_data_read,
    currency_data_read,
    rates_data_read,
    limited_users_data_read,
    limited_users_logs_data_read,
    detail_error,
  })(withNamespaces()(TasksCreate))
);
