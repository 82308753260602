import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";

import classnames from "classnames";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/logo-dark.png";

import firebase from "firebase/app";
import "firebase/database";
import moment from "moment";
import moment_time_zone from "moment-timezone";
//moment_time_zone().tz("Etc/GMT")

/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { shipping_data_read, detail_error } from "../../store/actions";
import Lottie from "react-lottie";
/***/
import vat_arabic from "../../assets/images/VAT_Arabic.png"; //"../../../assets/images/VAT_Arabic.png";

class PaymentChecker extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      data_by_lot_no: [],
      start_date: "",
      end_date: "",
      ready: [], //false,
      data_to_download_pdf: {},
      results: {},
      old_container_data: [],

      conversion_selected: {},
      invoice_details: {},

      reciept_start: null,
      reciept_end: null,

      hbl_start: null,
      hbl_end: null,

      date_start: "",
      date_end: "",

      show: false,
      data_to_show_in_table: [],
    };
  }

  // toggle(i) {
  //   var temp_ready = this.state.ready;
  //   temp_ready[i] = false;

  //   this.setState(
  //     {
  //       ready: temp_ready,
  //     },
  //     () => {
  //       setTimeout(() => {
  //         var second_temp = this.state.ready;
  //         second_temp[i] = true;
  //         this.setState({ ready: second_temp });
  //       }, 1);
  //     }
  //   );
  // }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }

  table_titles(data_taken) {
    var titles_to_show = [];

    var titles = [
      "HBL",
      "Shipper Name",
      "Receipt No",
      "Payment Date",
      "Payment Amount",
      "Total Bill",
      "Outstanding",
      "Employee",
    ];

    titles.forEach((element, i) => {
      titles_to_show.push(
        <Text
          key={i}
          style={{
            width: `${100 / 8}%`,
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 15,
          }}
        >
          {element}
        </Text>
      );
    });
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#CFD8DC",
          backgroundColor: "#CFD8DC",
          borderBottomWidth: 1,
          alignItems: "left",
          // height: 24,
          paddingTop: 10,
          paddingBotttom: 10,
          textAlign: "left",
          fontStyle: "bold",
        }}
      >
        {titles_to_show}
      </View>
    );
  }

  line_items(data_taken) {
    var list_to_show = [];

    data_taken.forEach((element, i) => {
      list_to_show.push(
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#CFD8DC",
            borderBottomWidth: 1,
            alignItems: "center",
            // height: 24,
            fontStyle: "bold",
            paddingTop: 5,
          }}
          key={i}
        >
          <Text
            style={{
              width: `${100 / 8}%`, //"20%",
              textAlign: "left",
              paddingLeft: 8,
              fontSize: 10,
            }}
          >
            {element.hbl}
          </Text>
          <Text
            style={{
              width: `${100 / 8}%`, //"20%",
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.shipper_name}
          </Text>
          <Text
            style={{
              width: `${100 / 8}%`, //"20%",
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.receipt_no}
          </Text>
          <Text
            style={{
              width: `${100 / 8}%`, //"20%",
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.payment_date}
          </Text>

          <Text
            style={{
              width: `${100 / 8}%`, //"20%",
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.payment_amount}
          </Text>
          <Text
            style={{
              width: `${100 / 8}%`, //"20%",
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.total_bill}
          </Text>
          <Text
            style={{
              width: `${100 / 8}%`, //"20%",
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.outstanding}
          </Text>
          <Text
            style={{
              width: `${100 / 8}%`, //"20%",
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.employee}
          </Text>
        </View>
      );
    });

    return list_to_show;
  }
  show_report() {
    const { ready, data_to_show_in_table } = this.state;

    console.log(data_to_show_in_table);

    if (data_to_show_in_table.length > 0) {
      var title = "Zainco Payment Checker Report";
      var data_taken = data_to_show_in_table;
      var table_titles = this.table_titles(data_taken);

      var line_items = this.line_items(data_taken);

      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                marginTop: 24,
              }}
            >
              <Text
                style={{
                  color: "#263238",
                  fontSize: 15,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 24,
                borderWidth: 1,
                borderColor: "#CFD8DC",
              }}
            >
              {table_titles}

              {line_items}
            </View>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          ></Page>
        </Document>
      );
    }

    /*
      <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            /> */
  }

  // componentDidMount() {
  //   firebase
  //     .database()
  //     .ref("organization/zaincocargo/container/")
  //     .once("value")
  //     .then((snapshot) => {
  //       var data_array = _.map(snapshot.val(), (val, uid) => {
  //         return { ...val, uid };
  //       });

  //       this.setState({
  //         old_container_data: data_array,
  //       });
  //     });
  // }

  show_report_based_on_hbl(data) {
    const { ready } = this.state;
    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "hbl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Shipper Name",
          field: "shipper_name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Receipt No",
          field: "receipt_no",
          sort: "asc",
          width: 100,
        },
        {
          label: "Payment Date",
          field: "payment_date",
          sort: "asc",
          width: 270,
        },
        {
          label: "Payment Amount",
          field: "payment_amount",
          sort: "asc",
          width: 200,
        },

        {
          label: "Total Bill", //"Receipt",
          field: "total_bill",
          sort: "asc",
          width: 100,
        },

        {
          label: "Outstanding", //"Receipt",
          field: "outstanding",
          sort: "asc",
          width: 100,
        },
        {
          label: "Employee",
          field: "employee",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data,
    };
    // const MyDocument = this.show_report();

    var export_data = [
      [
        "HBL",
        "Shipper Name",
        "Receipt No",
        "Payment Date",
        "Payment Amount",
        "Total Bill",
        "Outstanding",
        "Employee",
      ],
    ];

    data.forEach((element) => {
      export_data.push([
        element.hbl,
        element.shipper_name,
        element.receipt_no,
        element.payment_date,
        element.payment_amount,
        element.total_bill,
        element.outstanding,
        element.employee,
      ]);
    });

    return (
      <div>
        <div className="text-sm-right">
          <CSVLink filename="payment_checker.csv" data={export_data}>
            <Button type="button" color="primary" className="w-md">
              Export Data
            </Button>
          </CSVLink>
        </div>

        {/* <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button
                    onClick={() => {
                      //
                      this.setState({ data_to_show_in_table: data });
                      setTimeout(() => {
                        this.setState({ ready: false });
                      }, 1000);
                    }}
                  >
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table: data });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div> */}

        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }

  reciept_range_filter(data) {
    const {
      reciept_start,
      reciept_end,
      hbl_start,
      hbl_end,
      date_start,
      date_end,
      show,
    } = this.state;

    var start = "",
      end = "";

    if (reciept_start !== null) {
      start = reciept_start;
    }
    if (reciept_end !== null) {
      end = reciept_end;
    }
    var new_filter = [];

    if (show === true) {
      //add code here
      data.forEach((element) => {
        if (
          element.receipt_no > parseInt(start) &&
          element.receipt_no < parseInt(end)
        ) {
          new_filter.push(element);
        }
      });
    }
    return (
      <div>
        <Form className="repeater" encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">Start Reciept No</Label>
                <Input
                  type="number"
                  // id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = parseInt(event.target.value);
                    this.setState({
                      reciept_start: value,
                    });
                  }}
                  value={start}
                />
              </Col>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">End Reciept No</Label>
                <Input
                  type="number"
                  // id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = parseInt(event.target.value);
                    // if (value > start) {
                    this.setState({
                      reciept_end: value,
                      show: true,
                    });
                    // } else {
                    // alert(
                    //   "Please enter End Reciept no after the Start Reciept No!"
                    // );
                    // }
                  }}
                  value={end}
                />
              </Col>
            </Row>
          </div>
        </Form>

        {show === true ? (
          <div>{this.show_report_based_on_hbl(new_filter)}</div>
        ) : null}
      </div>
    );
  }
  hbl_range_filter(data) {
    const {
      reciept_start,
      reciept_end,
      hbl_start,
      hbl_end,
      date_start,
      date_end,
      show,
    } = this.state;

    var start = "",
      end = "";

    if (hbl_start !== null) {
      start = hbl_start;
    }
    if (hbl_end !== null) {
      end = hbl_end;
    }

    var new_filter = [];

    if (show === true) {
      //add code here
      data.forEach((element) => {
        if (element.hbl > parseInt(start) && element.hbl < parseInt(end)) {
          new_filter.push(element);
        }
      });
    }

    return (
      <div>
        <Form className="repeater" encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">Start HBL</Label>
                <Input
                  type="number"
                  // id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = parseInt(event.target.value);
                    this.setState({
                      hbl_start: value,
                    });
                  }}
                  value={start}
                />
              </Col>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">End HBL</Label>
                <Input
                  type="number"
                  // id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = parseInt(event.target.value);
                    // if (value > start) {
                    //   //add code

                    this.setState({
                      hbl_end: value,
                      show: true,
                    });
                    // } else {
                    //   // alert("Please enter End HBL no after the Start HBL No!");
                    // }
                  }}
                  value={end}
                />
              </Col>
            </Row>
          </div>
        </Form>

        {show === true ? (
          <div>{this.show_report_based_on_hbl(new_filter)}</div>
        ) : null}
      </div>
    );
  }

  date_range_filter(data) {
    const { date_start, date_end, show } = this.state;
    var new_filter = [];

    if (show === true) {
      // console.log(data);
      //                 payment_date_value=moment(second_element.date).valueOf()

      var date_start_value = moment(date_start).valueOf();
      var date_end_value = moment(date_end).valueOf();

      data.forEach((element) => {
        if (
          element.payment_date_value > date_start_value &&
          element.payment_date_value < date_end_value
        ) {
          new_filter.push(element);
        }
      });

      //add code here
    }
    return (
      <div>
        <Form className="repeater" encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">Start Date</Label>
                <Input
                  type="date"
                  id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = event.target.value;
                    this.setState({
                      date_start: value,
                    });
                  }}
                  value={date_start}
                />
              </Col>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">End Date</Label>
                <Input
                  type="date"
                  id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = event.target.value;

                    this.setState({
                      date_end: value,
                      show: true,
                    });

                    var date_start = moment(date_start).valueOf();
                    var date_end = moment(value).valueOf();

                    if (date_start < date_end) {
                      this.setState({
                        date_end: value,
                        show: true,
                      });
                    } else {
                      // alert(
                      //   "Please select End Date after the Start Date you have selected!"
                      // );
                    }
                  }}
                  value={date_end}
                />
              </Col>
            </Row>
          </div>
        </Form>
        {show === true ? (
          <div>{this.show_report_based_on_hbl(new_filter)}</div>
        ) : null}
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }

    const { shipping_data } = this.props;
    var data = shipping_data;

    data = _.map(data, (val, uid) => {
      return { ...val, uid };
    });

    var invoices = [];

    if (data.length !== 0) {
      /*
           HBL, Shipper Name, Receipt,Payment Date, Total Bill, Amount Paid, Remaining Balance.
      */

      var filtered_ = [];
      data.forEach((element, x) => {
        if (element.full_detail !== undefined) {
          ////
          const hbl = element.No;
          const { shipper_details, payments_details, calculations } =
            element.full_detail;

          if (payments_details !== undefined) {
            payments_details.forEach((second_element) => {
              var employee = "",
                payment_amount = 0,
                total_bill = 0,
                outstanding = 0,
                receipt_no = 0;
              if (second_element.log !== undefined) {
                employee = second_element.log.by;
              }
              if (
                second_element.payment_receipt_no !== "" &&
                second_element.payment_receipt_no !== undefined
              ) {
                receipt_no = parseInt(second_element.payment_receipt_no);
              }
              if (second_element.amount !== "") {
                payment_amount = parseFloat(second_element.amount).toFixed(2);
              }

              if (calculations.total_charges !== "") {
                total_bill = parseFloat(calculations.total_charges).toFixed(2);
              }

              outstanding = total_bill - payment_amount;

              var payment_date_value = 0;
              if (second_element.date !== "") {
                payment_date_value = moment(second_element.date).valueOf();
              }

              filtered_.push({
                hbl: hbl,
                shipper_name: shipper_details.name,
                receipt_no: receipt_no,
                payment_date: second_element.date,
                payment_amount: payment_amount,
                total_bill: total_bill,
                outstanding: parseFloat(outstanding).toFixed(2),
                employee: employee,
                payment_date_value: payment_date_value,
              });
            });
          }
        }
      });
    }

    return (
      <div>
        <div>
          <Nav pills className="bg-light rounded" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.setState({
                    show: false,
                    activeTab: "1",
                    reciept_start: null,
                    reciept_end: null,

                    hbl_start: null,
                    hbl_end: null,

                    date_start: "",
                    date_end: "",
                  });
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.setState({
                    show: false,
                    activeTab: "2",
                    reciept_start: null,
                    reciept_end: null,

                    hbl_start: null,
                    hbl_end: null,

                    date_start: "",
                    date_end: "",
                  });
                }}
              >
                Based on Reciept Range
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "3",
                })}
                onClick={() => {
                  this.setState({
                    show: false,
                    activeTab: "3",
                    reciept_start: null,
                    reciept_end: null,

                    hbl_start: null,
                    hbl_end: null,

                    date_start: "",
                    date_end: "",
                  });
                }}
              >
                Based on HBL Range
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "4",
                })}
                onClick={() => {
                  this.setState({
                    show: false,
                    activeTab: "4",
                  });
                }}
              >
                Based on Period
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <TabContent activeTab={this.state.activeTab} className="mt-4">
          <TabPane tabId="1" id="buy-tab">
            {this.show_report_based_on_hbl(filtered_)}
          </TabPane>
          <TabPane tabId="2" id="sell-tab">
            {this.reciept_range_filter(filtered_)}

            {/* {this.show_report_based_on_lot_no({ invoices })} */}
          </TabPane>
          <TabPane tabId="3" id="sell-tab">
            {this.hbl_range_filter(filtered_)}

            {/* {this.show_report_based_on_lot_no({ invoices })} */}
          </TabPane>
          <TabPane tabId="4" id="sell-tab">
            {this.date_range_filter(filtered_)}

            {/* {this.show_report_based_period({ invoices })} */}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

// export default Cash_Slip;
const mapStatetoProps = (state) => {
  const { shipping_data, error, loading } = state.Database;

  return {
    shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    shipping_data_read,
    detail_error,
  })(withNamespaces()(PaymentChecker))
);
