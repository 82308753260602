import React, { Component } from "react";
import Main from "./Main";
import { Container, Row, Col } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  detail_error,
  container_data_read,
} from "../../store/actions";
import Lottie from "react-lottie";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.container_data_read();
  }

  render() {
    const { user_details, container_data, loading } = this.props;

    // return <React.Fragment></React.Fragment>;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <React.Fragment>
        <Main user_details={user_details} container_data={container_data} />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user_details, error, loading, container_data } = state.Database;

  return {
    user_details,
    error,
    loading,
    container_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    detail_error,
    container_data_read,
  })(withNamespaces()(Index))
);
