import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
// Redux
import { connect } from "react-redux";

// actions
import {
  user_data_read,
  shipping_data_read,
  container_data_read,
  limited_users_logs_data_read,
  limited_users_data_read,
  currency_data_read,
  rates_data_read,
  detail_error,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
    this.props.user_data_read();
    // this.props.shipping_data_read();
    // this.props.container_data_read();
    this.props.limited_users_data_read();
    this.props.currency_data_read();
    this.props.rates_data_read();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  full_permission_menu() {
    return (
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{this.props.t("Menu")}</li>
          <li>
            <Link to="/shipments" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>{this.props.t("Shipments")}</span>
            </Link>
          </li>
          <li>
            <Link to="/add-edit-container" className="waves-effect">
              <i className="bx bx-package"></i>
              <span>{this.props.t("Add/Edit Container")}</span>
            </Link>
          </li>
          <li>
            <Link to="/add-edit-shipments" className="waves-effect">
              <i className="bx bx-detail"></i>
              <span>{this.props.t("Add/Edit Shipments")}</span>
            </Link>
          </li>
          <li>
            <Link to="/reports-and-reciepts" className="waves-effect">
              <i className="bx bx-note"></i>
              <span>{this.props.t("Reports and Reciepts")}</span>
            </Link>
          </li>

          <li>
            <Link to="/user-management" className="waves-effect">
              <i className="bx bx-user"></i>
              <span>{this.props.t("User Management")}</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }

  full_restricted_menu() {
    return (
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{this.props.t("Menu")}</li>
          <li>
            <Link to="/shipments" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>{this.props.t("Shipments")}</span>
            </Link>
          </li>
          <li>
            <Link to="/add-edit-container" className="waves-effect">
              <i className="bx bx-package"></i>
              <span>{this.props.t("Add/Edit Container")}</span>
            </Link>
          </li>
          <li>
            <Link to="/add-edit-shipments" className="waves-effect">
              <i className="bx bx-detail"></i>
              <span>{this.props.t("Add/Edit Shipments")}</span>
            </Link>
          </li>
          <li>
            <Link to="/reports-and-reciepts" className="waves-effect">
              <i className="bx bx-note"></i>
              <span>{this.props.t("Reports and Receipts")}</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
  render() {
    const { user_details } = this.props;

    // console.log({ user_details });
    if (user_details !== null) {
      if (user_details.role === "limited") {
        return <React.Fragment>{this.full_restricted_menu()}</React.Fragment>;
      } else {
        return <React.Fragment>{this.full_permission_menu()}</React.Fragment>;
      }
    }
    return <React.Fragment>{this.full_permission_menu()}</React.Fragment>;
  }
}

const mapStatetoProps = (state) => {
  const { user_details, shipping_data, upload_shipping_data, error, loading } =
    state.Database;

  return {
    user_details,
    shipping_data,
    upload_shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    detail_error,
    shipping_data_read,
    container_data_read,
    limited_users_logs_data_read,
    limited_users_data_read,
    currency_data_read,
    rates_data_read,
  })(withNamespaces()(SidebarContent))
);
