import React, { Component } from "react";
import Main from "./Main";

//i18n
import { withNamespaces } from "react-i18next";
import { Container, Row, Col } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  shipping_data_read,
  container_data_read,
  currency_data_read,
  rates_data_read,
  limited_users_data_read,
  limited_users_logs_data_read,
  detail_error,
} from "../../store/actions";
import Lottie from "react-lottie";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.shipping_data_read();
    this.props.container_data_read();
  }

  render() {
    const {
      // user_details,
      // shipping_data,
      loading,
      // container_data,
      // currency_data,
      // rates_data,
      // limited_users_data,
    } = this.props;

    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Main />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    error,
    loading,
    // shipping_data,
    // container_data,
    // currency_data,
    // rates_data,
    // limited_users_data,
  } = state.Database;

  return {
    user_details,
    error,
    loading,
    // shipping_data,
    // container_data,
    // currency_data,
    // rates_data,
    // limited_users_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    shipping_data_read,
    container_data_read,
    currency_data_read,
    rates_data_read,
    limited_users_data_read,
    limited_users_logs_data_read,
    detail_error,
  })(withNamespaces()(Index))
);
